import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { selectTenantInfo } from 'src/core/store/selectors/common.selector';
import { IAppState } from 'src/core/store/state/app.state';

@AutoUnsubscribe()
@Component({
  selector: 'app-retail-theme',
  templateUrl: './retail-theme.component.html',
  styleUrls: ['./retail-theme.component.less'],
})
export class RetailThemeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  html: any;
  constructor(private _store: Store<IAppState>) {}
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this._store.pipe(select(selectTenantInfo)).subscribe((result) => {
        if (result) {
          const main = result.mainColor;
          const gradient_color1 = result.secondColor;
          const gradient_color2 =  result.mainColor;
          this.html = `<style>${this.generateStyles(main, gradient_color1, gradient_color2)}</style>`;
        }
      })
    );
  }

  generateStyles(
    main: string,
    gradient_color1: string,
    gradient_color2: string
  ) {
    return `
    .red_gr {
      background: linear-gradient(-80deg, ${gradient_color1} 0, ${gradient_color2} 100%);
    }
    .btn_red:hover {
      background: linear-gradient(
        -80deg,
        ${gradient_color2} 0,
        ${gradient_color1} 100%
      );
    }
    .text_red,
    .showcase_link:hover,
    .btn_custom1:hover{
      color: ${main};
    }
    .link:hover {
      color: ${main};
    }
    input:checked ~ .checkmark {
      background-color: ${main};
    }
    .checkbox_wrap .checkmark {
      border-color: ${main};
    }
    .close:not(:disabled):not(.disabled):focus,
    .close:not(:disabled):not(.disabled):hover,
    .view_type.active,
    .view_type:hover,
    .contact_phone,
    .category_label strong,
    .side_menu li:hover > a,
    .cart_item__remove,
    .pagination .page-item .page-link:hover,
    .offcanvas-header .back_btn:hover,
    .slider_v1 .slick-arrow:hover:before {
      color: ${main};
    }
    .jq-checkbox.checked .jq-checkbox__div {
      border-bottom: 2px solid ${main};
      border-left: 2px solid ${main};
    }
    .slick-dots li.slick-active button,
    .slick-dots li:hover button {
      background: ${main};
      border-color: ${main};
    }
    .slider_v1 .slick-arrow:hover,
    .form-control.error {
      border-color: ${main};
    }
    .noUi-connect,
    .ps__thumb-y,
    .jq-radio.checked .jq-radio__div {
      background-color: ${main};
    }
    .btn_red,
    .header_cart__count,
    .item_label {
      background: linear-gradient(-80deg, ${gradient_color1} 0, ${gradient_color2} 100%);
    }
    `;
  }
}
