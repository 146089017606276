import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
//import { response } from 'express';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less'],
})
export class NotFoundComponent implements OnInit {
  response:any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('response') response?: any
  ) {
    this.response = !isPlatformBrowser(this.platformId) ? response : null;
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.statusCode = 404;
    }
  }
}
