<section>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <div>
          <h1>Ваша корзина</h1>
          <div *ngIf="cartItems && cartItems.length == 0">Ваша корзина пуста</div>
          <div *ngIf="cartItems && cartItems.length > 0" class="cart_list list_full">
            <div *ngFor="let cartItem of cartItems" class="cart_item">
              <div class="cart_item__main">
                <div class="cart_item__pic mr-4">
                  <a routerLink="{{'/catalog/' + cartItem.good.categoryAlias + '/' + cartItem.good.alias }}"><img
                      src="{{cartItem.good.previewImage}}" alt="" /></a>
                </div>
                <div class="cart_item__content mr-sm-4">
                  <h3>
                    <a
                      routerLink="{{'/catalog/' + cartItem.good.categoryAlias + '/' + cartItem.good.alias }}">{{cartItem.good.title}}</a>
                  </h3>
                  <!-- <p class="txt_grey">Ширина: 2000</p> -->
                </div>
              </div>
              <div class="cart_item__details">
                <div class="item_price">
                  <div *ngIf="!cartItem.good.priceEquality" class="old_price mx-2">{{cartItem.good.oldPrice |
                    currency:'':'' | replace:',00':''}} руб.</div>
                  <div class="current_price mx-2">
                    <span class="bigger">{{cartItem.good.price | currency:'':'' | replace:',00':''}}</span> руб.
                  </div>
                </div>
                <div class="mx-xl-4">
                  <div class="change_num text-nowrap">
                    <input (click)="decrement(cartItem.good.id)" type="button" value="-" class="button-minus"
                      data-field="quantity" />
                    <input #countInput type="text" (change)="countInputKeyDown(cartItem.good.id, $event.target.value)"
                      value="{{cartItem.count}}" name="quantity" class="quantity-field" />
                    <input (click)="increment(cartItem.good.id)" type="button" value="+" class="button-plus"
                      data-field="quantity" />
                  </div>
                </div>
                <div class="price_wrap">
                  <div class="current_price mx-2">
                    <span class="bigger">{{cartItem.good.price * cartItem.count | currency:'':'' |
                      replace:',00':''}}</span> руб.
                  </div>
                </div>
                <div class="cart_item__remove">
                  <a href="javascript:void(0);" (click)="remove(cartItem.good.id)"><span aria-hidden="true">×</span></a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="cartItems && cartItems.length > 0" class="cart_total d-md-flex">
            <!-- <div class="promo_input">
              <input
                type="text"
                placeholder="Введите номер купона"
                class="form-control"
              />
              <button type="button" class="btn btn_grey btn_xmd">
                Пересчитать
              </button>
            </div> -->
            <div class="my-md-3">
              <div class="d-flex align-items-center flex-wrap justify-content-center">
                <div class="total_title">Стоимость товаров:</div>
                <div class="total_sum text-nowrap my-2">
                  <span class="bigger mr-2">{{goodsCost | currency:'':'' | replace:',00':''}}</span>руб.
                </div>
              </div>
            </div>
            <div class="d-sm-flex my-md-4">
              <a href="javascript:void(0);" (click)="continue()" class="btn btn_custom btn_xmd btn_red my-2 size14">
                <span class="mr-2 icon" style="top: -2px">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13px"
                    height="13px">
                    <path fill-rule="evenodd" fill="rgb(255, 255, 255)"
                      d="M12.792,6.870 C13.053,6.605 13.053,6.187 12.792,5.922 C12.540,5.666 12.121,5.666 11.870,5.922 L7.143,10.726 L7.143,0.672 C7.143,0.303 6.854,-0.001 6.490,-0.001 C6.127,-0.001 5.829,0.303 5.829,0.672 L5.829,10.726 L1.111,5.922 C0.850,5.666 0.430,5.666 0.179,5.922 C-0.082,6.187 -0.082,6.605 0.179,6.870 L6.024,12.811 C6.276,13.067 6.696,13.067 6.947,12.811 L12.792,6.870 Z" />
                  </svg></span>
                Продолжить оформление
              </a>
            </div>
          </div>
        </div>

        <div *ngIf="cartItems && cartItems.length > 0 && isContinueBtnPressed" class="order_form__wrap">
          <h2>Оформление заказа</h2>
          <form *ngIf="!submitted && tenant.fullContextVm" #orderForm="ngForm">
            <div class="order_form__content">
              <div class="order_form_col">
                <h3>Контактные данные</h3>
                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="">Ваше ФИО<sup class="text_red bigger ml-1">*</sup></label>
                      <input [(ngModel)]="model.fullName" #fullName="ngModel" name="fullName" type="text"
                        class="form-control dark" [ngClass]="{'error': !fullName.valid && !fullName.pristine}"
                        placeholder="Фамилия Имя Отчество" required />
                    </div>
                  </div>
                  <div *ngIf="model.paymentMethod === 300 || model.paymentMethod == 400" class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="">E-mail<sup class="text_red bigger ml-1">*</sup></label>
                      <input [(ngModel)]="model.email" #email="ngModel" name="email" type="email"
                        class="form-control dark" placeholder="Введите e-mail" [ngClass]="{'error': !email.valid && !email.pristine}"  required/>
                      <!-- <input *ngIf="model.paymentMethod !== 300" [(ngModel)]="model.email" #email="ngModel" name="email" type="email"
                        class="form-control dark" placeholder="Введите e-mail. Не обязательно." /> -->
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="">Ваш телефон<sup class="text_red bigger ml-1">*</sup></label>
                      <input [(ngModel)]="model.phoneNumber" #phoneNumber="ngModel" name="phoneNumber" type="text" mask="0 000 000-0000" minlength="10"
                        class="form-control dark tel" placeholder="Введите номер телефона"
                        [ngClass]="{'error': !phoneNumber.valid && !phoneNumber.pristine}" required />
                    </div>
                  </div>
                </div>
              </div>
              <div class="order_form_col">
                <h3>Условия доставки:</h3>
                <p *ngIf="currentCity.transportDeliveryCost && currentCity.transportDeliveryCost > 0">
                  Доставка до транспортной компании будет стоить {{currentCity.transportDeliveryCost}}р
                </p>
                <div class="row d-flex radio_cards">
                  <div *ngIf="currentCity.cityDeliveryCost && currentCity.cityDeliveryCost > 0" class="radio_card col-md-4 col-6">
                    <input (change)="deliveryMethodChange()" [(ngModel)]="model.deliveryMethod" #deliveryMethod="ngModel" name="deliveryMethod"
                      [value]="100" checked type="radio" id="delivery_id1" />
                    <div  class="radio_card__content">
                      <div class="radio_card__icon">
                        <img src="../../assets/images/delivery-icon-1.png" alt="Курьером в черте города" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Курьером в черте города</h3>
                        <p>{{currentCity.cityDeliveryCost}}р</p>
                      </div>
                    </div>
                  </div>
                  <div class="radio_card col-md-4 col-6">
                    <input #pickupInput (change)="deliveryMethodChange()" [(ngModel)]="model.deliveryMethod" #deliveryMethod="ngModel" [value]="200"
                      name="deliveryMethod" type="radio" id="delivery_id2" />
                    <div   class="radio_card__content">
                      <div class="radio_card__icon">
                        <img src="../../assets/images/delivery-icon-2.png" alt="Со склада ТК" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Самовывоз</h3>
                        <p *ngIf="currentCity.address">Со склада {{currentCity.address}}</p>
                        <p *ngIf="!currentCity.address">Со склада ТК</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group mt-3">
                    <label for="">Город, улица, дом, квартира<sup *ngIf="!pickupInput.checked" class="text_red bigger ml-1">*</sup></label>
                    <input [(ngModel)]="model.fullAddress" #fullAddress="ngModel" name="fullAddress" type="text"
                      [ngClass]="{'error': !fullAddress.valid && !fullAddress.pristine}" class="form-control dark"
                      placeholder="Начните вводить ваш адрес" [required]="!pickupInput.checked" />
                  </div>
                </div>
              </div>
              <div class="order_form_col">
                <h3>Способы оплаты:</h3>
                <div class="row d-flex radio_cards">
                  <div *ngIf="!tenant.fullContextVm.cashDisabled" class="radio_card col-md-4 col-6">
                    <input [(ngModel)]="model.paymentMethod" #paymentMethod="ngModel" [value]="100" name="paymentMethod"
                      type="radio" id="payment_id1" />
                    <div class="radio_card__content">
                      <div class="radio_card__icon">
                        <img src="../../assets/images/payment-icon-2.png" alt="Наличными" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Наличными</h3>
                        <p>При доставке или самовывозе</p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tenant.fullContextVm.bankCardDisabled" class="radio_card col-md-4 col-6">
                    <input [(ngModel)]="model.paymentMethod" #paymentMethod="ngModel" [value]="200" name="paymentMethod"
                      type="radio" id="payment_id2" />
                    <div class="radio_card__content">
                      <div class="radio_card__icon">
                        <img src="../../assets/images/payment-icon-1.png" alt="Картой при получении" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Картой при получении</h3>
                        <p>Банковской картой при получении</p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tenant.fullContextVm.onlineDisabled" class="radio_card col-md-4 col-6">
                    <input [(ngModel)]="model.paymentMethod" #paymentMethod="ngModel" [value]="300" name="paymentMethod"
                      type="radio" id="payment_id3" />
                    <div class="radio_card__content">
                      <div class="radio_card__icon">
                        <img src="../../assets/images/payment-icon-1.png" alt="Картой онлайн" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Картой онлайн</h3>
                        <p>Банковской картой онлайн</p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!tenant.fullContextVm.legalEntityDisabled" class="radio_card col-md-4 col-6">
                    <input [(ngModel)]="model.paymentMethod" #paymentMethod="ngModel" [value]="400" name="paymentMethod"
                      type="radio" id="payment_id4" />
                    <div class="radio_card__content">
                      <div class="radio_card__icon" style="min-width: 42px; width:42px;">
                        <img src="../../assets/images/legacyEntity.svg" alt="Юр. лицо" />
                      </div>
                      <div class="radio_card__text">
                        <h3>Юр. лицо</h3>
                        <!-- <p>Банковской картой онлайн</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="order_form__total">
              <div class="order_form__comment">
                <div class="form-group">
                  <label>Комментарий к заказу</label>
                  <textarea [(ngModel)]="model.additionalInfo" #additionalInfo="ngModel" name="additionalInfo" rows="5"
                    class="form-control dark"
                    placeholder="Если у вас есть комментарии к заказу впишите их сюда"></textarea>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-end flex-wrap mb-md-5 mb-3 pb-2 mx-xl-3 justify-content-center">
                  <div class="total_title">Итого в корзине:</div>
                  <div class="total_sum text-nowrap">
                    <span class="bigger mr-2">{{goodsFinalCost | currency:'':'' | replace:',00':''}}</span>руб.
                  </div>
                </div>
              </div>
              <div class="ml-auto order_form__confirm">
                <button type="button" (click)="postOrder(orderForm)" class="btn btn_custom btn_md btn_red w-100 accept_order_button"
                  [disabled]="!orderForm.valid || isOrderBtnPressed">
                  <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span
                        class="path2"></span></span></span>
                  Подтверждаю заказ
                </button>
                <p class="text_sm mt-3">
                  Подтверждая заказ, вы соглашаетесь с условиями
                  <a target="_blank" routerLink="/dogovor" class="link">публичной оферты</a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
