<div *ngIf="currentGoods"  class="catalog_list">
  <div #catalogListAnchor  style="position: absolute; top: -100px; left: 0"></div>
  <div class="row no-gutters">
    <div *ngFor="let item of currentGoods" class="col-xl-3 col-md-4 col-6 col-xs-6">
      <div class="item_card">
        <div class="item_card__inner">
          <div class="item_card__pic">
            <a routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias }}"><img src="{{item.previewImage}}"
                alt="{{item.title}}"></a>
          </div>
          <p class="title"><a routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias }}">{{item.title}}</a>
          </p>
          <div class="d-flex align-items-center justify-content-center">
            <p *ngIf="!item.priceEquality" class="old_price mx-2">
              {{item.oldPrice | currency:'':'' | replace:',00':''}}
            </p>
            <p class="current_price mx-2">
              <span class="bigger">{{item.price | currency:'':'' | replace:',00':''}}</span> руб.
            </p>
          </div>
          <div class="item_card__bottom">
            <a href="javascript:void(0);" (click)="addToCart(item?.id, item?.price)" class="btn btn_custom btn_md btn_red w-100 add_to_cart_button">
              <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span
                    class="path2"></span></span></span>
              В корзину
            </a>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="d-none d-md-block">
    <div class="row">
      <div class="col-12">
        <div class="catalog_banner mb-4">
          <a href="#">
            <img src="images/catalog-banner.jpg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div> -->

  </div>

  <ul *ngIf="pageCount > 1 && pageItems?.length > 0" style="clear: both;" class="pagination">
    <li class="page-item page_text__item" [ngClass]="{'disabled' : currentPage == 1}">
      <a href="javascript:void(0);" (click)="pageDecr()" class="page-link p-0">Назад</a>
    </li>
    <li class="page-item arrow_item" [ngClass]="{'arrow_disabled': currentPage == 1}">
      <a href="javascript:void(0);" (click)="pageDecr()" class="page-link"><i class="fa fa-angle-left"></i></a>
    </li>
    <li *ngFor="let page of pageItems" class="page-item"><a (click)="pageClicked(page.value)"
        *ngIf="!page.isHidden || page.isPlug" [ngClass]="{'page-link': true, 'current': page.isActive}"
        href="javascript:void(0);">{{page.isPlug ? '...' : page.value}}</a></li>

    <li class="page-item arrow_item" [ngClass]="{'arrow_disabled': currentPage == pageCount}">
      <a href="javascript:void(0);" (click)="pageIncr()" class="page-link"><i class="fa fa-angle-right"></i></a>
    </li>
    <li class="page-item page_text__item " [ngClass]="{'disabled': currentPage == pageCount}">
      <a class="page-link p-0" href="javascript:void(0);" (click)="pageIncr()">Вперед</a>
    </li>
  </ul>
