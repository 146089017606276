import { AuthService } from './../shared/services/auth.service';
import {
  MainCartApiClient,
  CartGoodPreviewCardVM,
  CartItemVM,
  OrderDto,
  MainOrderApiClient,
  InitOrderVM,
  TenantCityVM,
  TenantInfoVM,
} from './../services/main.service';
import { Component, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { CartService } from '../services/cart.service';
import { IAppState } from 'src/core/store/state/app.state';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { GetCartInfo } from 'src/core/store/actions/common.actions';
import { Subscription } from 'rxjs';
import {
  selectCartInfo,
  selectCurrentCity,
  selectTenantContextInfo,
  selectUserInfo,
} from 'src/core/store/selectors/common.selector';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less'],
})
@AutoUnsubscribe()
export class CartComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  cartItems: CartGoodPreviewCardVM[];
  localCartItems: CartItemVM[];
  isInited: boolean = false;
  submitted: boolean = false;
  isContinueBtnPressed: boolean = false;
  isOrderBtnPressed: boolean = false;
  goodsCost: number = 0;
  goodsFinalCost: number = 0;
  currentCity: TenantCityVM;
  tenant: TenantInfoVM;
  model = new OrderDto();
  constructor(
    private _store: Store<IAppState>,
    private mainCartApiClient: MainCartApiClient,
    private cartService: CartService,
    private mainOrderApiClient: MainOrderApiClient,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.model.deliveryMethod = 200;
    this.model.paymentMethod = 100;
  }

  ngOnDestroy(): void {}

  postOrder(orderForm) {
    const self = this;
    this.isOrderBtnPressed = true;
    if (orderForm.valid) {
      const initOrderDto = new InitOrderVM();
      initOrderDto.order = this.model;
      initOrderDto.cartGoodPreviewCards = this.cartItems;
      initOrderDto.calltouchSessionId = this.cookieService.get('_ct_session_id');
      this.mainOrderApiClient
        .initOrder(initOrderDto)
        .toPromise()
        .then((x) => {
          if (x.success) {
            self.cartService.clearCart();
            self._store.dispatch(new GetCartInfo());
            if (x.shouldLogin) {
              self.authService.logIn(x.username, x.password, "/personal");
            } else {
              if(x.userExist)
              this.router.navigate(['/personal']);
              else
              this.router.navigate(['/']);
            }
          } else {
            self.isContinueBtnPressed = false;
          }
        });
    }
  }

  countInputKeyDown(id, value) {
    this.cartService.setCountOfCartItemById(id, value);
    this._store.dispatch(new GetCartInfo());
  }

  continue() {
    this.isContinueBtnPressed = true;
  }

  remove(id: string) {
    this.cartService.removeFromCartByid(id);
    this._store.dispatch(new GetCartInfo());
  }

  decrement(id: string) {
    this.cartService.decrCartItemById(id);
    this._store.dispatch(new GetCartInfo());
  }

  increment(id: string) {
    this.cartService.incrCartItemById(id);
    this._store.dispatch(new GetCartInfo());
  }

  calcFinalCost() {
    let finalCost = this.goodsCost;
    if (this.currentCity.transportDeliveryCost > 0)
      finalCost += this.currentCity.transportDeliveryCost;
    if (
      this.model.deliveryMethod == 100 &&
      this.currentCity.cityDeliveryCost > 0
    )
      finalCost += this.currentCity.cityDeliveryCost;
    this.goodsFinalCost = finalCost;
  }

  deliveryMethodChange() {
    this.calcFinalCost();
  }

  ngOnInit(): void {
    this._store.dispatch(new GetCartInfo());
    const self = this;
    let currentCartItems = this.cartService.getCurrentCart().items;
    if (!currentCartItems || currentCartItems.length == 0) this.cartItems = [];
    else
      this.mainCartApiClient
        .getCartItems(currentCartItems)
        .toPromise()
        .then((cartItems) => {
          self.cartItems = cartItems;
          let isDeprecated = self.cartService.deleteDeprecated(cartItems);
          if (isDeprecated) this.ngOnInit();
          else self.isInited = true;
        });

    this.subscriptions.add(
      this._store.pipe(select(selectUserInfo)).subscribe((user) => {
        if (user) {
          self.model.phoneNumber = user.phoneNumber;
          self.model.email = user.email;
          self.model.fullName = user.fullName;
          self.model.fullAddress = user.deliveryAddress;
        }
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(selectCurrentCity)).subscribe((resultCity) => {
        if (resultCity) {
          self.currentCity = resultCity;
          this.subscriptions.add(
            this._store
              .pipe(select(selectTenantContextInfo))
              .subscribe((resultTenant) => {
                if (resultTenant) {
                  self.tenant = resultTenant;

                  this.subscriptions.add(
                    this._store
                      .pipe(select(selectCartInfo))
                      .subscribe((result) => {
                        if (result) {
                          self.goodsCost = result.cost;
                          self.calcFinalCost();
                          if (this.isInited) {
                            self.localCartItems = this.cartService.getCurrentCart().items;
                            let cartItemToRemove: CartGoodPreviewCardVM;
                            self.cartItems.forEach((cartItem) => {
                              let localCartItem = self.localCartItems.find(
                                (x) => x.id == cartItem.good.id
                              );
                              if (!localCartItem) cartItemToRemove = cartItem;
                              else cartItem.count = localCartItem.count;
                            });
                            if (cartItemToRemove) {
                              self.cartItems = self.cartItems.filter(
                                (x) => x.good.id != cartItemToRemove.good.id
                              );
                            }
                          }
                        }
                      })
                  );
                }
              })
          );
        }
      })
    );
  }
}
