import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { selectTenantContextInfo } from 'src/core/store/selectors/common.selector';
import { IAppState } from 'src/core/store/state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantInfoVM } from '../services/main.service';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-display-html',
  templateUrl: './display-html.component.html',
  styleUrls: ['./display-html.component.less'],
})
@AutoUnsubscribe()
export class DisplayHtmlComponent implements OnInit, OnDestroy {
  html: any;
  subscriptions: Subscription = new Subscription();
  constructor(
    private _store: Store<IAppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    if (!isPlatformServer) window.scrollTo(0, 0);
    let type = null;
    this.subscriptions.add(
      this.route.data.subscribe((data) => {
        type = data.type;
      })
    );

    let self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectTenantContextInfo)).subscribe((result) => {
        if (result && result?.fullContextVm && type) {
          self.html = self.getPageHtmlByCode(type, result);
        }
      })
    );
  }

  getPageHtmlByCode(type: any, tenantInfo: TenantInfoVM) {
    let tenantContext = tenantInfo?.fullContextVm;
    switch (type) {
      case 'dostavka': {
        return tenantContext.deliveryPageInnerHtml;
      }
      case 'privacy-policy': {
        return tenantContext.privacyPolicyPageInnerHtml;
      }
      case 'terms-of-use': {
        return tenantContext.termsOfUsePageInnerHtml;
      }
      case 'o_kompanii': {
        return tenantContext.aboutPageInnerHtml;
      }
      case 'reviews': {
        return tenantContext.rewiewsPageInnerHtml;
      }
      case 'garantyy': {
        return tenantContext.guaranteePageInnerHtml;
      }
      case 'rekvizity': {
        return tenantContext.requisitesPageInnerHtml;
      }
      case 'vozvrat': {
        return tenantContext.returnPageInnerHtml;
      }
      case 'contact': {
        return tenantContext.contactPageInnerHtml;
      }
      case 'dogovor': {
        return tenantContext.publicOfferPageInnerHtml;
      }
      default: {
        return 'Страницы не существует';
      }
    }
  }
}
