<div style="clear: both;" class="catalog_filter border-bottom">
  <div class="my-lg-4 filter_trigger__wrap" *ngIf="!isSearchPage">
    <a href="javascript:void(0);" (click)="modalService.openCatalogMobileFilters()" class="modal-trigger" data-target="filterModal"><span class="mr-3 bigger"><i class="fa fa-sliders" aria-hidden="true"></i></span><strong>Параметры</strong></a>
  </div>
  <!-- <div class="checkbox_wrap catalog_filter_col d-none d-lg-block">
    <input type="checkbox" id="in_stock">
    <label for="in_stock" class="mb-0">В наличии</label>
  </div> -->
  <!-- <div *ngIf="showFilters && filters && filters.manufacturers && filters.manufacturers.length > 0" class="dropdown catalog_filter_col">
    <a href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">{{currentManufacturer ? currentManufacturer : 'Производитель'}}</a>
    <div class="dropdown-menu dropdown_v1 dropdown_scrollable">
      <a class="dropdown-item"  href="javascript:void(0)" (click)="resetFilter('manufacturer')">Сбросить</a>
      <a *ngFor="let manufacturer of filters.manufacturers" class="dropdown-item"  href="javascript:void(0)" (click)="handleFilterChange('manufacturer', manufacturer)">{{manufacturer.displayTitle}}</a>
    </div>
  </div> -->
  <div class="dropdown catalog_filter_col">
    <a href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">Сортировать</a>
    <div class="dropdown-menu dropdown_v1">
      <!-- <a class="dropdown-item" href="#">по популярности</a> -->
      <a class="dropdown-item"  href="javascript:void(0)" (click)="changeSortType('priceAsc')">по возрастанию цены</a>
      <a class="dropdown-item"  href="javascript:void(0)" (click)="changeSortType('priceDesc')">по убыванию цены</a>
    </div>
  </div>
  <div class="dropdown catalog_filter_col d-none d-lg-block">
    <a href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">Товаров на странице: <span>{{takeCount}}</span></a>
    <div class="dropdown-menu dropdown_v1">
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeTakeCount(24)">24</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeTakeCount(36)">36</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeTakeCount(48)">48</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeTakeCount(72)">72</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="changeTakeCount(96)">96</a>
    </div>
  </div>
  <!-- <div class="catalog_filter_col d-none d-lg-flex">
    <p class="m-0 mr-3">Отображение товаров:</p>
    <div>
      <a href="javascript:void(0);" class="view_type active">
        <i class="fa fa-th-large" aria-hidden="true"></i>
      </a>
      <a href="#" class="view_type">
        <i class="fa fa-th-list" aria-hidden="true"></i>
      </a>
    </div>
  </div> -->
</div>
