import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MainGoodApiClient } from '../services/main.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-main-page-carusel',
  templateUrl: './main-page-carusel.component.html',
  styleUrls: ['./main-page-carusel.component.less'],
})
export class MainPageCaruselComponent implements OnInit {
  items: any;
  isEmpty: boolean = true;
  prerenderMode: boolean;
  constructor(
    private _mainGoodApiClient: MainGoodApiClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.prerenderMode = !isPlatformBrowser(this.platformId);
    this.getItems();
  }

  ngOnInit(): void {
  }

  getItems(){
    let self = this;
    this._mainGoodApiClient
      .getMainSliderItems()
      .toPromise()
      .then((res) => {
        if (res && res.length > 0) {
          res.forEach(x => x.path = this.removeWatermarkFromImage(x.path))
          self.isEmpty = res.length == 0;
          self.items = res;
        }
      });
  }

  removeWatermarkFromImage(url) {
    if (!url)
      return url;
    if (url.includes('quality'))
      return url;

    if (url.includes('?'))
      url += '&quality=100';
    else
      url += '?quality=100';

    return url;
  }

}
