import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe()
export class ModalService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  constructor(@Inject(DOCUMENT) private doc) {}

  openCatalogMobileFilters() {
    this.doc.getElementById('filterModal').classList.add('show');
  }

  closeCatalogMobileFilters() {
    this.doc.getElementById('filterModal').classList.remove('show');
  }

  ngOnDestroy(): void {}
}
