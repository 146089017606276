<section>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <h1>Заявка на бесплатный дизайн-проект кухни</h1>
        <div style="max-width: 710px">
          <p>
            Свяжитесь с нами - мы всегда готовы Вам помочь. Наши
            квалифицированные менеджеры ответят на любые ваши вопросы, начиная
            от подбора мебели под интерьер, заканчивая дизайн проектом.
          </p>
          <app-kitchen-design-project-form></app-kitchen-design-project-form>
        </div>
      </div>
    </div>
  </div>
</section>
