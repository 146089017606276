import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kitchen-design-project',
  templateUrl: './kitchen-design-project.component.html',
  styleUrls: ['./kitchen-design-project.component.less']
})
export class KitchenDesignProjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
