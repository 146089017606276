import { createSelector } from '@ngrx/store';

import { IAppState } from '../state/app.state';
import { ICommonState } from '../state/common.state';

const commonState = (state: IAppState) => state.common;

export const selectUserInfo = createSelector(
  commonState,
  (state: ICommonState) => state.user
);

export const selectTenantInfo = createSelector(
  commonState,
  (state: ICommonState) => state.tenant
);

export const selectTenantContextInfo = createSelector(
  commonState,
  (state: ICommonState) => state.tenant
);

export const selectCategories = createSelector(
  commonState,
  (state: ICommonState) => state.categories
);

export const selectCategoriesList = createSelector(
  commonState,
  (state: ICommonState) => state.categoriesList
);

export const selectCurrentCategory = createSelector(
  commonState,
  (state: ICommonState) => state.currentCategory
);


export const selectCities = createSelector(
  commonState,
  (state: ICommonState) => state.cities
);

export const selectCurrentCity = createSelector(
  commonState,
  (state: ICommonState) => state.currentCity
);

export const selectCartInfo = createSelector(
  commonState,
  (state: ICommonState) => state.cartInfo
);




