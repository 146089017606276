<div *ngIf="categories && categories.length > 0" class="d-none d-md-block">
  <div class="row">
    <div class="col-12" style="margin-top: 40px;">
      <ul class="showcase_links">
        <li *ngFor="let item of categories" class="col-xl-3 col-sm-6">
          <a routerLink="{{'/catalog/' + item?.alias}}" class="showcase_link">
            <span *ngIf="item.image" class="icon mr-3"
              ><img src="../../assets/images/kukhnya.png" alt="" /></span
            >{{ item.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
