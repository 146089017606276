import { ForgotPasswordComponent } from './../forgot-password/forgot-password.component';
import { UserInfoDto } from './../services/main.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.less'],
})
export class LoginFormComponent implements OnInit {
  loading = false;
  login: string = '';
  password: string = '';
  emailRegister: string = '';
  passwordRegister: string = '';
  loginPopupVisible: boolean = false;
  validationMessage:string;
  user: UserInfoDto;
  @ViewChild(ForgotPasswordComponent) forgotPasswordPopup: ForgotPasswordComponent;
  constructor(private authService: AuthService, private router: Router) {
    this.user = this.authService.getUserInfo();
  }

  closeLocationPopup() {
    this.loginPopupVisible = false;
  }

  showForgotPasswordModal(){
    this.forgotPasswordPopup.forgotPasswordPopupVisible = true;
  }

  async auth() {
    const self = this;
    if(!self.login){
      this.validationMessage = "Заполните поле E-Mail";
      return;
    }
    if(!self.password){
      this.validationMessage = "Заполните поле Пароль";
      return;
    }
    this.loading = true;
    this.validationMessage = null;
    this.authService.lastAuthenticatedPath = this.router.url;
    const result = await this.authService.logIn(self.login, self.password);

    if (!result.isOk) {
      this.loading = false;
      this.validationMessage = result.message;
    } else {
      this.closeLocationPopup();
      this.user = result.data;
    }
  }

  enterActionAuth(e){
    if(e.keyCode === 13)  this.auth();
  }

  enterActionRegister(e){
    if(e.keyCode === 13)  this.register();
  }

 processTabSwitch(){
  this.validationMessage = null;
 }

  async register() {
    if(!this.emailRegister){
      this.validationMessage = "Заполните поле E-Mail";
      return;
    }
    if(!this.passwordRegister){
      this.validationMessage = "Заполните поле Пароль";
      return;
    }
    this.authService.lastAuthenticatedPath = this.router.url;
    const result:any = await this.authService.register(this.emailRegister, this.passwordRegister);
    this.validationMessage = null;
    if (!result.isOk) {
      this.loading = false;
      this.validationMessage = result.message;
    } else {
      this.closeLocationPopup();
      this.user = result.data;
    }
  }

  async logout() {
    this.authService.lastAuthenticatedPath = this.router.url;
    this.authService.logOut();

    this.user = this.authService.getUserInfo();
  }

  ngOnInit(): void {}
}
