import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectCurrentCategory } from 'src/core/store/selectors/common.selector';
import { IAppState } from 'src/core/store/state/app.state';
import { CategoryItemVM } from '../services/main.service';

@Component({
  selector: 'app-catalog-subitems',
  templateUrl: './catalog-subitems.component.html',
  styleUrls: ['./catalog-subitems.component.less']
})
export class CatalogSubitemsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  subcategories: CategoryItemVM[]
  constructor(private _store: Store<IAppState>) {
   }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this._store
        .pipe(select(selectCurrentCategory))
        .subscribe((currentCategory) => {
          if(currentCategory)
            this.build(currentCategory);
        })
    );
  }

  build(currentCategory: CategoryItemVM) {
    const self = this;
    self.subcategories = currentCategory.childs;

  }
}
