import { selectCategoriesList } from './../../core/store/selectors/common.selector';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { selectCategories } from 'src/core/store/selectors/common.selector';
import { IAppState } from 'src/core/store/state/app.state';
import { CategoryItemVM } from '../services/main.service';

@Component({
  selector: 'app-main-page-category',
  templateUrl: './main-page-category.component.html',
  styleUrls: ['./main-page-category.component.less'],
})
@AutoUnsubscribe()
export class MainPageCategoryComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  categories: CategoryItemVM[] = [];
  constructor(private _store: Store<IAppState>) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    let self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectCategoriesList)).subscribe((result) => {
        if(result && result.length > 0){
          let visibleCategories = result.filter(x => x.visibleMainPage);
          self.categories = visibleCategories.sort((n1,n2) => {
            if (n1.priority > n2.priority) {
                return 1;
            }

            if (n1.priority < n2.priority) {
                return -1;
            }

            return 0;
        });;
        }
      })
    );
  }
}
