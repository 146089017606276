import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/core/store/state/app.state';
import { selectCategories, selectTenantContextInfo } from 'src/core/store/selectors/common.selector';
import { CategoryItemVM, TenantFullContextVM, TenantInfoVM } from '../services/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.less'],
})
@AutoUnsubscribe()
export class LeftMenuComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  categories: CategoryItemVM[] = [];
  tenantInfo: TenantInfoVM;
  constructor(private _store: Store<IAppState>, private router: Router) {}

  ngOnInit(): void {
    let self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectCategories)).subscribe((result) => {
        self.categories = result;
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(selectTenantContextInfo)).subscribe((result) => {
        self.tenantInfo = result;
      })
    );

  }

  goToCatalogPage(alias){
      this.router.navigate(['/catalog/' + alias]);
      document.getElementById('sideMenuCloseButton').click();
  }

  ngOnDestroy(): void {}
}
