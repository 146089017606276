import {
  AuthApiClient,
  MainAccountApiClient,
  MainAuthApiClient,
  UserInfoDto,
} from './../../services/main.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { resultMemoize, Store } from '@ngrx/store';
import { GetUser } from 'src/core/store/actions/common.actions';
import { IAppState } from 'src/core/store/state/app.state';

const defaultPath = '/';

@Injectable()
export class AuthService {
  private _user: UserInfoDto;
  get loggedIn(): boolean {
    if (!this._user) {
      this._user = this.getUserFromStorage();
    }
    return !!this._user;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private router: Router,
    private _store: Store<IAppState>,
    private authApiClient: MainAuthApiClient,
    private mainAccountApiClient: MainAccountApiClient
  ) {}

  async logIn(login: string, password: string, returnUrl: string = null) {
    try {
      let user = await this.authApiClient.auth(login, password).toPromise();
      this._user = user;
      localStorage.setItem('user', JSON.stringify(user));
      this._store.dispatch(new GetUser());
      let path = returnUrl ?? this._lastAuthenticatedPath;
      this.router.navigate([path]);
      //this.router.navigate(['/home']);

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
        message: 'Пользователь с указанным логином и паролем не найден',
      };
    }
  }

  async register(username: string, password: string) {
    const result = await this.mainAccountApiClient
      .register(username, password)
      .toPromise();
    if (!result.success)
      return {
        isOk: false,
        message: result.errorMessage,
      };

    this._user = result.user;
    localStorage.setItem('user', JSON.stringify(result.user));
    this._store.dispatch(new GetUser());
    this.router.navigate([this._lastAuthenticatedPath]);

    return {
      isOk: true,
      data: this._user,
    };
  }

  getToken() {
    let token = this._user?.accessToken;

    if (!token) {
      const user = this.getUserFromStorage();
      token = user?.accessToken;
      this._user = user;
      localStorage.setItem('user', JSON.stringify(user));
    }
    return token;
  }
  getUserInfo() {
    return this.getUserFromStorage();
  }

  async getUser() {
    try {
      this._user = await this.mainAccountApiClient.getUser().toPromise();

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  }

  async createAccount(login, password) {
    try {
      this.router.navigate(['/create-account']);
      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: 'Failed to create account',
      };
    }
  }

  async changePassword(login: string, recoveryCode: string) {
    try {


      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: 'Failed to change password',
      };
    }
  }

  async resetPassword(login: string) {
    try {
      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: 'Failed to reset password',
      };
    }
  }

  async logOut() {
    this._user = null;
    localStorage.removeItem('user');
    this._store.dispatch(new GetUser());
    this.router.navigate([this._lastAuthenticatedPath]);
  }

  private getUserFromStorage(): UserInfoDto {
    return JSON.parse(localStorage.getItem('user'));
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'change-password/:recoveryCode',
    ].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig.path;
    }

    return isLoggedIn || isAuthForm;
  }
}
