import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { max } from 'rxjs/operators';
import { CatalogFiltersVM } from '../services/main.service';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-catalog-filter',
  templateUrl: './catalog-filter.component.html',
  styleUrls: ['./catalog-filter.component.less'],
})
export class CatalogFilterComponent implements OnInit {
  @Input() incomedFilterModel: CatalogFiltersVM;
  @Output() filterModelEvent = new EventEmitter<CatalogFiltersVM>();
  currentFilterModel: CatalogFiltersVM;
  outputFilterModel: CatalogFiltersVM;
  filterPanelStates = [
    {
      type: 'price',
      opened: true,
    },
    {
      type: 'manufacturer',
      opened: false,
    },
    {
      type: 'height',
      opened: true,
    },
    {
      type: 'material',
      opened: false,
    },
    {
      type: 'width',
      opened: true,
    },
    {
      type: 'depth',
      opened: true,
    },
    {
      type: 'color',
      opened: false,
    },
  ];
  subscriptions: Subscription = new Subscription();

  constructor(public modalService: ModalService) { }

  ngOnDestroy(): void { }

  ngOnInit(): void { }


  validateFilterModelBeforeApply(filterModel) {
    if (!filterModel.maxDepth)
      filterModel.maxDepth = this.incomedFilterModel.maxDepth;
    if (!filterModel.maxHeight)
      filterModel.maxHeight = this.incomedFilterModel.maxHeight;
    if (!filterModel.maxPrice)
      filterModel.maxPrice = this.incomedFilterModel.maxPrice;
    if (!filterModel.maxWidth)
      filterModel.maxWidth = this.incomedFilterModel.maxWidth;
    if (!filterModel.minDepth)
      filterModel.minDepth = this.incomedFilterModel.minDepth;
    if (!filterModel.minHeight)
      filterModel.minHeight = this.incomedFilterModel.minHeight;
    if (!filterModel.minPrice)
      filterModel.minPrice = this.incomedFilterModel.minPrice;
    if (!filterModel.minWidth)
      filterModel.minWidth = this.incomedFilterModel.minWidth;
  }



  applyFilter() {
    this.validateFilterModelBeforeApply(this.currentFilterModel);
    this.outputFilterModel = this.cloneCatalogFilters(this.currentFilterModel);
    this.filterModelEvent.emit(this.outputFilterModel);
    this.modalService.closeCatalogMobileFilters();
  }

  resetFilter() {
    this.currentFilterModel = this.cloneCatalogFilters(this.incomedFilterModel);
    this.clearArrayFilterValues();
    this.applyFilter();
  }

  resetFilterByCode(code) {
    switch (code) {
      case "price":
        this.currentFilterModel.maxPrice = this.incomedFilterModel.maxPrice;
        this.currentFilterModel.minPrice = this.incomedFilterModel.minPrice;
        break;
      case "manufacturer":
        this.currentFilterModel.manufacturers = [];
        break;
      case "material":
        this.currentFilterModel.materials = [];
        break;
      case "height":
        this.currentFilterModel.maxHeight = this.incomedFilterModel.maxHeight;
        this.currentFilterModel.minHeight = this.incomedFilterModel.minHeight;
        break;
      case "width":
        this.currentFilterModel.minWidth = this.incomedFilterModel.minWidth;
        this.currentFilterModel.maxWidth = this.incomedFilterModel.maxWidth;
        break;
      case "depth":
        this.currentFilterModel.maxDepth = this.incomedFilterModel.maxDepth;
        this.currentFilterModel.minDepth = this.incomedFilterModel.minDepth;
        break;

    }
  }

  proccessFilterModel(filterModel: CatalogFiltersVM) {
    if (filterModel != null) {
      this.incomedFilterModel = this.cloneCatalogFilters(filterModel);
      this.currentFilterModel = filterModel;
      this.clearArrayFilterValues();
    }
  }

  clearArrayFilterValues() {
    this.currentFilterModel.manufacturers = [];
    this.currentFilterModel.materials = [];
  }

  isChecked(type, value) {
    if (type == 'material')
      return this.currentFilterModel.materials.some((x) => x == value);
    if (type == 'manufacturer')
      return this.currentFilterModel.manufacturers.some(
        (x) => x.id == value.id
      );
  }

  materialCheckBoxChanged(event, material) {
    if (event.target.checked === true) {
      this.currentFilterModel.materials.push(material);
    } else {
      this.currentFilterModel.materials =
        this.currentFilterModel.materials.filter((x) => x !== material);
    }
  }

  manufacturerCheckBoxChanged(event, manufacturer) {
    if (event.target.checked === true) {
      this.currentFilterModel.manufacturers.push(manufacturer);
    } else {
      this.currentFilterModel.manufacturers =
        this.currentFilterModel.manufacturers.filter(
          (x) => x.id !== manufacturer.id
        );
    }
  }

  handleFilterPanelClick(type) {
    let state = this.filterPanelStates.find((x) => x.type == type);
    state.opened = !state.opened;
  }

  checkIfOpened(type) {
    let state = this.filterPanelStates.find((x) => x.type == type);

    return state.opened;
  }

  cloneCatalogFilters(input: CatalogFiltersVM): CatalogFiltersVM {
    let model = new CatalogFiltersVM({
      manufacturers: input.manufacturers,
      materials: input.materials,
      minWidth: input.minWidth,
      maxWidth: input.maxWidth,
      minHeight: input.minHeight,
      maxHeight: input.maxHeight,
      minDepth: input.minDepth,
      maxDepth: input.maxDepth,
      minPrice: input.minPrice,
      maxPrice: input.maxPrice,
      filtersNeeded: input.filtersNeeded,
    });

    return model;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes['incomedFilterModel'];
    if (change) this.proccessFilterModel(change.currentValue);
  }
}
