<section>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
        <app-catalog-filter [incomedFilterModel]="incomedFilterModel" (filterModelEvent)="outputFilterModel = $event"></app-catalog-filter>
      </div>
      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <h1>Подборка товаров</h1>
        <div>
          <app-catalog-top-filter (changeSortTypeEvent)="changeSortType($event)" (changeTakeCountEvent)="changeTakeCount($event)"></app-catalog-top-filter>
          <app-catalog-items [advancedFilters]="outputFilterModel" [selectionType]="selectionType" [sortType]="sortType" [take]="takeCount" (filterModelEvent)="proccessFilterModel($event)"></app-catalog-items>
        </div>
      </div>
    </div>
  </div>
</section>
