 <section *ngIf="good">
  <div class="container">
    <div class="row">

      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

<div class="col-xl-9 col-lg-8 col-12">
  <app-breadcrumbs></app-breadcrumbs>
  <div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="product_slider mb-sm-5 mb-2">

          <gallery id="mainGallery" style="height: 427px;" [counter]="false" *ngIf="images?.length > 0" [gallerize]="images" [items]="images"></gallery>
           <div *ngIf="isServer && good.images.length > 0">
             <img *ngFor="let image of good.images" [src]="image" alt="{{good?.title}}">
           </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="product_details">
          <h1>{{good?.title}}</h1>
          <div class="d-flex my-3">
            <p *ngIf="good.oldPrice != good.price" class="old_price mx-2">
             {{good.oldPrice | currency:'':'' | replace:',00':''}} руб.
            </p>
            <p class="current_price mx-2">
              <span class="bigger">{{good?.price | currency:'':'' | replace:',00':''}}</span> руб.
            </p>
          </div>

          <div *ngIf="good.restCount">Остаток на складе: {{good.restCount}}</div>
          <div class="my-3 d-flex flex-wrap">

            <a href="javascript:void(0);" (click)="addToCart(good?.id, good?.price)" class="btn btn_custom btn_md btn_red mr-3 my-2 modal-trigger add_to_cart_button" data-target="cartModal"><span class="d-flex add_to_cart_button"><span class="bigger mr-3"><span class="icon-product-order-button-icon"></span></span> В корзину</span></a>

            <!-- <a href="#" class="btn btn_custom btn_md btn_grey my-2">Купить в 1 клик</a> -->
          </div>

          <div *ngIf="good.similarSizeGoods && good.similarSizeGoods.length > 0" class="my-3">
            <h3>Выбор размера</h3>
            <div class="dropdown">
              <a href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">{{good.size.width}}x{{good.size.depth}}</a>
              <div class="dropdown-menu dropdown_scroll size14">
                <a *ngFor="let item of good.similarSizeGoods" class="dropdown-item" routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias}}">{{item.title}}</a>
              </div>
            </div>
          </div>
          <div *ngIf="good.similarColorGoods && good.similarColorGoods.length > 0" class="my-3">
            <h3>Выбор цвета</h3>
            <div class="dropdown">
              <a href="javascript:void(0);" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">{{good.color}}</a>
              <div class="dropdown-menu dropdown_scroll size14">
                <a *ngFor="let item of good.similarColorGoods" class="dropdown-item" routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias}}">{{item.title}}</a>
              </div>
            </div>
          </div>
          <div *ngIf="good.parentGoods && good.parentGoods.length > 0" class="my-3">
            <h3>Входит в состав набора:</h3>
            <a *ngFor="let item of good.parentGoods" class="" routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias}}">{{item.title}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
         <div class="mobile_tabscollapse" id="details_additional">
          <ul class="nav nav-tabs tabs_custom1">
            <li class="nav-item ">
              <a class="nav-link subheading active" href="#tab_1" data-toggle="tab">Характеристики</a>
            </li>
            <li *ngIf="modules && modules.length > 0" class="nav-item ">
              <a class="nav-link  subheading" href="#tab_2" data-toggle="tab">Модули</a>
            </li>
            <li *ngIf="structures && structures.length > 0" class="nav-item ">
              <a class="nav-link  subheading" href="#tab_4" data-toggle="tab">Состав набора</a>
            </li>
            <li *ngIf="isKitchen" class="nav-item ">
              <a class="nav-link nav-link-white subheading red_gr" href="#tab_3" data-toggle="tab">Дизайн проект</a>
            </li>
            <li *ngIf="exhibitionImages?.length > 0" class="nav-item ">
              <a class="nav-link nav-link-white subheading red_gr" href="#tab_6" data-toggle="tab">Фото покупателей</a>
            </li>
          </ul>
          <div class="tab-content lgrey_bg">
            <div role="tabpanel" class="tab-pane show active" id="tab_1">
              <div class="card-header p-0" id="headingtab_1">
                <button class="collapse_trigger p-3 collapsed" type="button" data-toggle="collapse" data-target="#collapsetab_1" aria-expanded="true" aria-controls="collapsetab_1">
                 <span class="title">Характеристики</span>
                </button>
              </div>
              <div id="collapsetab_1" class="collapse" aria-labelledby="headingtab_1">
                <div class="p-3">
                  <ul class="specifications mt-sm-3">
                    <li *ngIf="good.size.width && good.size.width != 0">
                      <div  class="specifications_item">
                        <div class="specifications_name">
                          Ширина
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">{{good.size.width}}</div>
                      </div>
                    </li>
                    <li *ngIf="good.size.height && good.size.height != 0">
                      <div  class="specifications_item">
                        <div class="specifications_name">
                          Высота
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">{{good.size.height}}</div>
                      </div>
                    </li>
                    <li *ngIf="good.size.depth && good.size.depth != 0">
                      <div  class="specifications_item">
                        <div class="specifications_name">
                          Глубина
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">{{good.size.depth}}</div>
                      </div>
                    </li>

                    <li *ngIf="good.manufacturer">
                      <div class="specifications_item">
                        <div class="specifications_name">
                          Производитель
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">
                          <a href="javascript:void(0);">{{good.manufacturer}}</a>
                        </div>
                      </div>
                    </li>
                    <li *ngIf="good.color">
                      <div class="specifications_item">
                        <div class="specifications_name">
                          Цвет
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">
                          <span class="item">{{good.color}}</span>
                          <!-- <span class="item">Кембридж Крем Квазар</span>
                          <span class="item">Патина бежевая</span>
                          <span class="item">Элм</span> -->
                        </div>
                      </div>
                    </li>
                    <li *ngIf="good.material">
                      <div class="specifications_item">
                        <div class="specifications_name">
                          Материал
                          <span class="specifications_separator"></span>
                        </div>
                        <div class="specifications_value">
                          <span class="item">{{good.material}}</span>
                          <!-- <span class="item">МДФ</span> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr class="m-0">
                  <div *ngIf="good.features || good.propertiesString || (good.composition && !good.isBase)" class="py-3">
                    <h4>Особенности</h4>
                    <div class="text_sm">
                      <p *ngIf="good.composition && !good.isBase">{{good.composition}}</p>
                      <p *ngIf="good.features">{{good.features}}</p>
                      <p [innerHTML]="good.propertiesString" *ngIf="good.propertiesString"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="modules && modules.length > 0" role="tabpanel" class="tab-pane " id="tab_2">
              <div class="card-header p-0" id="headingtab_2">
                <button class="collapse_trigger p-3 collapsed" type="button" data-toggle="collapse" data-target="#collapsetab_2" aria-expanded="true" aria-controls="collapsetab_2">
                 <span class="title">Модули</span>
                </button>
              </div>
              <div  id="collapsetab_2" class="collapse" aria-labelledby="headingtab_2">
                <div class="p-3">
                  <h3>Модули системы</h3>
                  <div class="catalog_filter  border-bottom d-none d-md-flex">
                    <div class="dropdown catalog_filter_col">
                      <a href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">Сортировать</a>
                      <div class="dropdown-menu dropdown_v1">
                        <a class="dropdown-item" href="javascript:void(0);" (click)="orderModules('priceAsc')">по возрастанию цены</a>
                        <a class="dropdown-item"  href="javascript:void(0);" (click)="orderModules('priceDesc')">по убыванию цены</a>
                      </div>
                    </div>
                  </div>
                  <div class="elements_slider slider_v1">
                    <div *ngFor="let module of modules" class="elements_col">
                      <div class="elements_card">
                        <a routerLink="{{'/catalog/' + module.categoryAlias + '/' + module.alias }}" class="elements_card__pic">
                          <img src="{{module.previewImage}}" alt="{{module.title}}">
                        </a>
                        <div>
                          <h3><a routerLink="{{'/catalog/' + module.categoryAlias + '/' + module.alias }}">{{module.title}}</a></h3>
                          <!-- <ul class="list_inline txt_light text_sm mb-0">
                            <li>
                              <div class="mr-2 bold">Ширина:</div>
                              <div class="text-nowrap">150</div>
                            </li>
                            <li>
                              <div class="mr-2 bold">Высота:</div>
                              <div class="text-nowrap">920</div>
                            </li>
                            <li>
                              <div class="mr-2 bold">Глубина:</div>
                              <div class="text-nowrap">318</div>
                            </li>
                          </ul> -->
                        </div>
                        <div class="bottom">
                          <!-- <div class="text-nowrap mr-3"><strong class="mr-2 bigger">{{module.price | currency:'':'' | replace:',00':''}}</strong>руб.</div> -->
                          <div class="d-flex">
                            <p *ngIf="!module.priceEquality" class="old_price mx-2">
                              {{module.oldPrice | currency:'':'' | replace:',00':''}}
                            </p>
                            <p class="current_price mx-2">
                              <span class="bigger">{{module.price | currency:'':'' | replace:',00':''}}</span> руб.
                            </p>
                          </div>

                          <div class="mt-3">
                            <a href="javascript:void(0);" (click)="addToCart(module?.id, module?.price)" class="btn btn_custom btn_md btn_red add_to_cart_button">
                              <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span class="path2"></span></span></span>
                              В корзину
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="structures && structures.length > 0" role="tabpanel" class="tab-pane" id="tab_4">
              <div class="card-header p-0" id="headingtab_4">
                <button class="collapse_trigger p-3 collapsed" type="button" data-toggle="collapse" data-target="#collapsetab_4" aria-expanded="true" aria-controls="collapsetab_4">
                 <span class="title">Состав набора</span>
                </button>
              </div>
              <div id="collapsetab_4" class="collapse" aria-labelledby="headingtab_4">
                <div class="p-3">
                  <h3>Состав набора</h3>
                  <!-- <div class="catalog_filter  border-bottom d-none d-md-flex">
                    <div class="dropdown catalog_filter_col">
                      <a href="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown__title">Сортировать</a>
                      <div class="dropdown-menu dropdown_v1">
                        <a class="dropdown-item" href="javascript:void(0);" (click)="orderModules('priceAsc')">по возрастанию цены</a>
                        <a class="dropdown-item"  href="javascript:void(0);" (click)="orderModules('priceDesc')">по убыванию цены</a>
                      </div>
                    </div>
                  </div> -->
                  <div class="elements_slider slider_v1">
                    <div *ngFor="let structure of structures" class="elements_col">
                      <div class="elements_card">
                        <a routerLink="{{'/catalog/' + structure.categoryAlias + '/' + structure.alias }}" class="elements_card__pic">
                          <img src="{{structure.previewImage}}" alt="{{structure.title}}">
                        </a>
                        <div>
                          <h3><a routerLink="{{'/catalog/' + structure.categoryAlias + '/' + structure.alias }}">{{structure.title}}</a></h3>
                        </div>
                        <div class="bottom">
                          <div class="text-nowrap mr-3"><strong class="mr-2 bigger">{{structure.price | currency:'':'' | replace:',00':''}}</strong>руб <strong> x {{structure.count}}</strong></div>
                          <div class="mt-3">
                            <a href="javascript:void(0);" (click)="addToCart(structure?.id, structure?.price)" class="btn btn_custom btn_md btn_red add_to_cart_button">
                              <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span class="path2"></span></span></span>
                              В корзину
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isKitchen" role="tabpanel" class="tab-pane" id="tab_3">
              <div class="card-header p-0" id="headingtab_3">
                <button class="collapse_trigger p-3 collapsed" type="button" data-toggle="collapse" data-target="#collapsetab_3" aria-expanded="true" aria-controls="collapsetab_3">
                 <span class="title">Дизайн проект</span>
                </button>
              </div>
              <div id="collapsetab_3" class="collapse" aria-labelledby="headingtab_3">
                <div class="p-3" style="max-width: 710px">
                 <app-kitchen-design-project-form></app-kitchen-design-project-form>
                </div>
              </div>
            </div>
            <div *ngIf="exhibitionImages?.length > 0" role="tabpanel" class="tab-pane" id="tab_6">
              <div class="card-header p-0" id="headingtab_6">
                <button class="collapse_trigger p-3 collapsed" type="button" data-toggle="collapse" data-target="#collapsetab_6" aria-expanded="true" aria-controls="collapsetab_6">
                 <span class="title">Фото покупателей</span>
                </button>
              </div>
              <div id="collapsetab_6" class="collapse" aria-labelledby="headingtab_6">
                <div class="p-3" style="max-width: 710px">
                  <gallery *ngIf="exhibitionImages?.length > 0" id="exGallery" [counter]="false" [gallerize]="exhibitionImages" [items]="exhibitionImages"></gallery>
                </div>
              </div>
            </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</div>
</div>
 </section>
