import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MainNewsApiClient } from '../services/main.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-news-carusel',
  templateUrl: './news-carusel.component.html',
  styleUrls: ['./news-carusel.component.less'],
})
export class NewsCaruselComponent implements OnInit {
  items: any;
  prerenderMode: boolean;
  constructor(private newApiClient: MainNewsApiClient, @Inject(PLATFORM_ID) private platformId: Object) {
    this.prerenderMode = !isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.loadNews();
  }

  loadNews() {
    let self = this;
    this.newApiClient
      .getList()
      .toPromise()
      .then((res) => {
        res.forEach(x => x.image = this.removeWatermarkFromImage(x.image));
        self.items = res;
      });
  }

  removeWatermarkFromImage(url) {
    if (!url)
      return url;
    if (url.includes('quality')) {
      if (!url.includes('width=320'))
        url += '&width=320';
      return url;
    }


    if (url.includes('?'))
      url += '&quality=100';
    else
      url += '?quality=100&width=320';

    if (!url.includes('width=320'))
      url += '&width=320';

    return url;
  }

}
