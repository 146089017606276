import { SitemapComponent } from './sitemap/sitemap.component';
import { SelectionComponent } from './selection/selection.component';
import { KitchenDesignProjectComponent } from './kitchen-design-project/kitchen-design-project.component';
import { ProfileComponent } from './profile/profile.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchComponent } from './search/search.component';
import { GoodPageComponent } from './good-page/good-page.component';
import { DisplayHtmlComponent } from './display-html/display-html.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { NewsListComponent } from './news-list/news-list.component';
import { MainPageComponent } from './main-page/main-page.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { CatalogLayoutComponent } from './catalog-layout/catalog-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { CartComponent } from './cart/cart.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
  },
  // {
  //   path: 'sitemap.xml',
  //   component: SitemapComponent,
  // },
  {
    path: 'catalog',
    component: CatalogLayoutComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'selection',
    component: SelectionComponent,
  },
  {
    path: 'catalog/:category',
    component: CatalogLayoutComponent,
  },
  {
    path: 'news',
    component: NewsListComponent,
  },
  {
    path: 'news/:alias',
    component: NewsItemComponent,
  },
  {
    path: 'dostavka',
    data: { type: 'dostavka' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'privacy-policy',
    data: { type: 'privacy-policy' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'dogovor',
    data: { type: 'dogovor' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'terms-of-use',
    data: { type: 'terms-of-use' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'o_kompanii',
    data: { type: 'o_kompanii' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'reviews',
    data: { type: 'reviews' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'garantyy',
    data: { type: 'garantyy' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'rekvizity',
    data: { type: 'rekvizity' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'vozvrat',
    data: { type: 'vozvrat' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'contact',
    data: { type: 'contact' },
    component: DisplayHtmlComponent,
  },
  {
    path: 'catalog/:category/:good',
    component: GoodPageComponent,
  },
  {
    path: 'order',
    component: CartComponent,
  },
  {
    path: 'personal',
    component: ProfileComponent,
  },
  {
    path: 'personal-edit',
    component: ProfileEditComponent,
  },
  {
    path: '3d-project',
    component: KitchenDesignProjectComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
