<div *ngIf="loginPopupVisible" class="modal show"  id="enterModal">
  <div class="modal_overlay"></div>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <button (click)="closeLocationPopup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body p-4">
        <ul class="nav nav-tabs tabs_custom2 justify-content-center mb-4 py-3 px-sm-3" id="enter_option" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" (click)="processTabSwitch()" id="autorization-tab" data-toggle="tab" href="#autorization" role="tab" aria-controls="autorization" aria-selected="true"><span class="underline">Вход</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="processTabSwitch()" id="registration-tab" data-toggle="tab" href="#registration" role="tab" aria-controls="registration" aria-selected="false"><span class="underline">Регистрация</span></a>
          </li>
        </ul>
        <p class="text-center" style="color:red; font-size:14px;" *ngIf="validationMessage">{{validationMessage}}</p>
        <div class="tab-content mt-4" id="autorizationContent">
          <div class="tab-pane fade show active" id="autorization" role="tabpanel">
            <div class="px-sm-5">
              <p class="txt_grey text-center">Для входа используйте логин и&nbsp;пароль</p>
              <form class="my-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input (keydown)="enterActionAuth($event)" type="text" [(ngModel)]="login" name="login" class="form-control dark" placeholder="Ваш E-mail">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input (keydown)="enterActionAuth($event)" type="password" [(ngModel)]="password" name="password" class="form-control dark" placeholder="Пароль">
                      <div class="text-right mt-1 modal-trigger">
                        <a href="javascript:void(0);" class="txt_grey text_sm modal-trigger" (click)="showForgotPasswordModal()">Забыли пароль?</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-center txt_grey">
                  <p>Или войдите, через социальную сеть</p>
                  <ul class="list_inline my-2 justify-content-center">
                    <li>
                      <a href="#" class="social_network" style="background: #3b58a0"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li>
                      <a href="#" class="social_network" style="background: #4e7db2"><i class="fa fa-vk" aria-hidden="true"></i></a>
                    </li>
                    <li>
                      <a href="#" class="social_network" style="background: #f29216"><i class="fa fa-odnoklassniki" aria-hidden="true"></i></a>
                    </li>
                    <li>
                      <a href="#" class="social_network" style="background: #044a88"><svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px"><path fill-rule="evenodd"  fill="rgb(237, 169, 42)" d="M13.790,2.017 C12.319,0.672 10.488,-0.000 8.301,-0.000 C6.003,-0.000 4.044,0.764 2.427,2.294 C0.810,3.825 -0.000,5.728 -0.000,8.006 C-0.000,10.190 0.770,12.067 2.308,13.640 C3.853,15.213 5.927,16.000 8.538,16.000 C10.112,16.000 11.657,15.680 13.171,15.037 C13.665,14.828 13.906,14.267 13.705,13.774 L13.705,13.774 C13.497,13.265 12.911,13.030 12.401,13.245 C11.084,13.806 9.793,14.087 8.535,14.087 C6.533,14.087 4.982,13.483 3.882,12.272 C2.786,11.065 2.236,9.645 2.236,8.016 C2.236,6.247 2.825,4.772 4.001,3.587 C5.173,2.405 6.619,1.811 8.331,1.811 C9.908,1.811 11.242,2.298 12.328,3.270 C13.415,4.243 13.958,5.467 13.958,6.942 C13.958,7.951 13.708,8.793 13.211,9.462 C12.714,10.135 12.197,10.467 11.660,10.467 C11.370,10.467 11.225,10.314 11.225,10.004 C11.225,9.753 11.245,9.459 11.281,9.119 L11.894,4.155 L9.786,4.155 L9.651,4.641 C9.115,4.207 8.525,3.988 7.886,3.988 C6.872,3.988 6.003,4.390 5.282,5.190 C4.557,5.989 4.198,7.021 4.198,8.281 C4.198,9.511 4.518,10.507 5.160,11.261 C5.802,12.018 6.572,12.393 7.475,12.393 C8.281,12.393 8.970,12.057 9.543,11.388 C9.974,12.031 10.610,12.351 11.449,12.351 C12.684,12.351 13.751,11.819 14.650,10.751 C15.549,9.687 16.000,8.401 16.000,6.897 C16.000,4.991 15.266,3.362 13.790,2.017 ZM8.937,9.527 C8.565,10.024 8.120,10.275 7.603,10.275 C7.251,10.275 6.968,10.092 6.754,9.727 C6.536,9.361 6.428,8.907 6.428,8.362 C6.428,7.690 6.579,7.148 6.882,6.737 C7.185,6.326 7.560,6.117 8.008,6.117 C8.397,6.117 8.743,6.270 9.045,6.580 C9.348,6.890 9.500,7.301 9.500,7.817 C9.497,8.460 9.309,9.028 8.937,9.527 Z"/></svg></a>
                    </li>
                    <li>
                      <a href="#" class="social_network" style="background: #d7143a"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="9px" height="16px"><path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M8.205,-0.000 L5.692,-0.000 C3.147,-0.000 0.578,1.701 0.578,5.500 C0.578,7.468 1.500,9.001 3.190,9.873 L0.097,14.938 C-0.050,15.178 -0.054,15.449 0.087,15.664 C0.223,15.874 0.474,16.000 0.756,16.000 L2.320,16.000 C2.676,16.000 2.953,15.844 3.086,15.572 L5.986,10.440 L6.198,10.440 L6.198,15.360 C6.198,15.707 6.521,16.000 6.904,16.000 L8.271,16.000 C8.700,16.000 9.000,15.729 9.000,15.340 L9.000,0.700 C9.000,0.288 8.673,-0.000 8.205,-0.000 ZM6.198,8.160 L5.824,8.160 C4.377,8.160 3.512,7.091 3.512,5.300 C3.512,3.073 4.604,2.280 5.626,2.280 L6.198,2.280 L6.198,8.160 Z"/></svg></a>
                    </li>
                    <li>
                      <a href="#" class="social_network" style="background: #3f7fea"><i class="fa fa-google" aria-hidden="true"></i></a>
                    </li>
                  </ul>
                  <p class="text_sm">Нужно будет подтвердить авторизацию в открывшемся окне</p>
                </div> -->
                <div class="text-center mt-5">
                  <button type="button" (click)="auth()" class="btn btn_custom btn_md btn_red" style="width: 150px">Войти</button>
                </div>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="registration" role="tabpanel">
            <div class="px-sm-5">
              <p class="txt_grey text-center">Для регистрации используйте вашу почту и пароль</p>
              <form class="my-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input (keydown)="enterActionRegister($event)" type="text" name="emailRegister" [(ngModel)]="emailRegister" class="form-control dark" placeholder="E-mail">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input (keydown)="enterActionRegister($event)" type="password" name="passwordRegister" [(ngModel)]="passwordRegister" class="form-control dark" placeholder="Пароль">
                    </div>
                  </div>
                </div>
                <!-- <p class="txt_grey text-center">Отправим вам письмо для подтверждения</p> -->
                <div class="checkbox_wrap align-items-start my-5">
                  <!-- <input type="checkbox" id="agreement" checked class="mr-3"> -->
                  <label class="m-0" for="agreement"><strong>Регистрируясь, вы соглашаетесь с <a routerLink="/privacy-policy" class="link">политикой конфиденциальности</a> и&nbsp;<a class="link" routerLink="/terms-of-use">условиями передачи информации</a></strong></label>
                </div>
                <div class="text-center mt-5">
                  <button type="button" (click)="register()" class="btn btn_custom btn_md btn_red">Зарегистрироваться</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-forgot-password></app-forgot-password>
