import { selectUserInfo } from 'src/core/store/selectors/common.selector';
import { UserInfoDto, MainOrderApiClient, OrderItemVM } from './../services/main.service';
import { IAppState } from 'src/core/store/state/app.state';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less'],
})
@AutoUnsubscribe()
export class ProfileComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  user: UserInfoDto;
  orders: OrderItemVM[];
  selectedOrder: OrderItemVM;
  constructor(private _store: Store<IAppState>, private _mainOrderApiClient: MainOrderApiClient) {}

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectUserInfo)).subscribe((userInfo) => {
        if (userInfo)
          self.user = userInfo;
      })
    );
    this.startup();
  }

  async startup() {
    this.orders = await this._mainOrderApiClient.getList().toPromise();
  }
}
