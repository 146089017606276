import { SitemapApiClient } from './../services/main.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.less'],
})
export class SitemapComponent implements OnInit {
  constructor(private apiClient: SitemapApiClient) {}

  ngOnInit(): void {
    // document.open();
    // this.sitemap();
  }

  sitemap() {
    // this.apiClient
    //   .get()
    //   .toPromise()
    //   .then((x) => {
    //     let parsedXml = $.parseXML(x.htmlContent);
    //  document.write(x.htmlContent);
    // //document.write(x.htmlContent);
    //     //document.close();
    //   });
  }
}
