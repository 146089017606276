import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainNewsApiClient } from '../services/main.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.less'],
})
export class NewsItemComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mainNewsApiClient: MainNewsApiClient
  ) {}

  alias: any;
  news: any;

  ngOnInit(): void {
    this.alias = this.route.snapshot.paramMap.get('alias');
    this.getNewsItem(this.alias);
  }

  getNewsItem(alias: string) {
    let self = this;
    this.mainNewsApiClient.getByAlias(alias).toPromise().then((res) => {self.news = res;});
  }
}
