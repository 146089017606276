import { MainAccountApiClient } from './../services/main.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordPopupVisible = false;
  isPasswordReset = false;
  errorMessage: string;
  isLoading = false;

  constructor(private mainAccountApiClient: MainAccountApiClient) {}

  closePopup() {
    this.forgotPasswordPopupVisible = false;
    this.isPasswordReset = false;
    this.errorMessage = null;
    this.isLoading = false;
  }

  resetPassword(email: string) {
    this.isLoading = true;
    const self = this;
    this.mainAccountApiClient
      .forgotPassword(email)
      .toPromise()
      .then((x) => {
        self.isLoading = false;
        if (x.success) {
          self.isPasswordReset = true;
        }
        else{
          self.errorMessage = x.message;
        }
      });
  }

  ngOnInit(): void {}
}
