import { trigger } from '@angular/animations';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GetCartInfo } from 'src/core/store/actions/common.actions';
import { IAppState } from 'src/core/store/state/app.state';
import { CartService } from '../services/cart.service';
import { GoodPreviewCardVM, MainGoodApiClient } from '../services/main.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-good-section-carusel',
  templateUrl: './carusel.component.html',
  styleUrls: ['./carusel.component.less'],
})
export class GoodSectionCaruselComponent implements OnInit {
  @Input() type: string;
  @Input() title: string;
  @Input() sectionClass: string;
  isEmpty: boolean = true;
  items: GoodPreviewCardVM[];
  prerenderMode: boolean;
  constructor(
    private _store: Store<IAppState>,
    private goodApiClient: MainGoodApiClient,
    private cartService: CartService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.prerenderMode = !isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.getItems();
  }

  addToCart(id: string, price: number) {
    this.cartService.addToCartById(id, price);
    this._store.dispatch(new GetCartInfo());
  }

  getItems() {
    let self = this;
    this.goodApiClient
      .getBySelectionTypeCode(this.type)
      .toPromise()
      .then((res) => {
        res.forEach(x => x.previewImage = this.removeWatermarkFromImage(x.previewImage));
        self.items = res;
        self.isEmpty = res.length == 0;
      });
  }

  removeWatermarkFromImage(url) {
    if (!url)
      return url;
    if (url.includes('quality'))
      return url;

    if (url.includes('?'))
      url += '&quality=100';
    else
      url += '?quality=100';

    return url;
  }
}
