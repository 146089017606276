<section>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <h1>{{ news?.header }}</h1>
        <div class="my-4">
          <img [src]="news?.image" alt="{{news?.header}}" />
        </div>
        <p>
          {{ news?.content }}
        </p>
        <div class="col-xl-9 col-lg-8 col-12"></div>
      </div>
    </div>
  </div>
</section>
