<div class="page" *ngIf="isTenantExists === true">
  <app-header></app-header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
  <app-retail-theme></app-retail-theme>
  <div *ngIf="dynamicScripts && platformBrowser" [innerHTML]="dynamicScripts | safeHtml" runScripts></div>
  <div id="dynamicScripts" *ngIf="dynamicScripts && !platformBrowser" [innerHTML]="dynamicScripts | safeHtml"></div>
</div>
<div style=" margin: 0 auto; margin-top: 400px; margin-left: 30%;" class="page" *ngIf="isTenantExists === false">
  <span style="font-size: 48px">Сайт находится в разработке</span>
</div>

