import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class TokenInterceptor {
  /**
   * Creates an instance of TokenInterceptor.
   * @param {OidcSecurityService} auth
   * @memberof TokenInterceptor
   */
  host: string;
  constructor(public auth: AuthService, private router: Router,  @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject('host') _host?: string) {
    this.host = _host;
  }

  /**
   * Intercept all HTTP request to add JWT token to Headers
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenInterceptor
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
        "Nika-Ref": isPlatformServer(this.platformId) ? this.host : ""
      },
    });

    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x, request, next)));
  }

  private handleAuthError(
    err: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // handle your auth error or rethrow
    switch (err.status) {
      case 401:
      case 403:
      case 419:
        console.log(err);
        this.auth.logOut();
        return of(err.message);
    }
    return throwError(err);
  }
}
