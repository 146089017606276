<div class="container">
  <div class="row">
    <app-left-layout class="col-xl-3 col-lg-4 col-12"></app-left-layout>
    <div style="z-index: 0 !important" class="col-xl-9 col-lg-8 col-12">
      <div class="row">

        <app-main-page-carusel class="col-xl-12 col-12 d-sm-block"></app-main-page-carusel>

        <!-- <div class="col-xl-3 col-12 d-none d-lg-block">
          <div class="d-flex flex-wrap">
            <div class="showcase_banner">
              <a href="#" class="mb-4 d-block">
                <img src="../../assets/images/showcase-banner-1.jpg" alt="" />
              </a>
            </div>
            <div class="showcase_banner">
              <a href="#" class="mb-4 d-block">
                <img src="../../assets/images/showcase-banner-2.jpg" alt="" />
              </a>
            </div>
          </div>
        </div> -->
      </div>
     <app-main-page-category></app-main-page-category>
    </div>
  </div>
</div>

<app-good-section-carusel
  sectionClass="py-md-5 py-3"
  type="hot"
  [title]="'Хиты продаж'"
></app-good-section-carusel>
<app-good-section-carusel
  sectionClass="lblue_bg py-md-5 py-3"
  type="bestprice"
  [title]="'Цена месяца'"
>
</app-good-section-carusel>

<section id="mainPageSeoBlock" class="py-md-5 py-3"><div id="mainPageSeoBlockText" class="container"></div></section>

<section class="py-md-5 py-3">
  <app-news-carusel></app-news-carusel>
</section>



