import { Action } from '@ngrx/store';

export enum ECommonActions {
  GetUser = '[Common] Get User',
  GetUserSuccess = '[Common] Get User Success',
  GetTenant = '[Common] Get Tenant',
  GetTenantSuccess = '[Common] Get Tenant Success',
  GetTenantContext = '[Common] Get Tenant Context',
  GetTenantContextSuccess = '[Common] Get Tenant Context Success',
  GetCategories = '[Common] Get Categories',
  GetCategoriesSuccess = '[Common] Get Categories Success',
  GetCities = '[Common] GetCities',
  GetCitiesSuccess = '[Common] GetCities Success',
  GetCurrentCity = '[Common] GetCurrentCity',
  GetCurrentCitySuccess = '[Common] GetCurrentCity Success',
  GetCurrentCategory = '[Common] GetCurrentCategory',
  GetCurrentCategorySuccess = '[Common] GetCurrentCategory Success',
  GetCartInfo = '[Common] GetCartInfo',
  GetCartInfoSuccess = '[Common] GetCartInfo Success',
}

export class GetUser implements Action {
  public readonly type = ECommonActions.GetUser;
  constructor() {}
}

export class GetUserSuccess implements Action {
  public readonly type = ECommonActions.GetUserSuccess;
  constructor(public payload: any) {}
}

export class GetTenant implements Action {
  public readonly type = ECommonActions.GetTenant;
  constructor() {}
}

export class GetTenantSuccess implements Action {
  public readonly type = ECommonActions.GetTenantSuccess;
  constructor(public payload: any) {}
}

export class GetTenantContext implements Action {
  public readonly type = ECommonActions.GetTenantContext;
  constructor() {}
}

export class GetTenantContextSuccess implements Action {
  public readonly type = ECommonActions.GetTenantContextSuccess;
  constructor(public payload: any) {}
}

export class GetCategories implements Action {
  public readonly type = ECommonActions.GetCategories;
  constructor() {}
}

export class GetCategoriesSuccess implements Action {
  public readonly type = ECommonActions.GetCategoriesSuccess;
  constructor(public payload: any) {}
}

export class GetCities implements Action {
  public readonly type = ECommonActions.GetCities;
  constructor() {}
}

export class GetCitiesSuccess implements Action {
  public readonly type = ECommonActions.GetCitiesSuccess;
  constructor(public payload: any) {}
}

export class GetCurrentCity implements Action {
  public readonly type = ECommonActions.GetCurrentCity;
  constructor(public id: any) {}
}

export class GetCurrentCitySuccess implements Action {
  public readonly type = ECommonActions.GetCurrentCitySuccess;
  constructor(public payload: any) {}
}

export class GetCurrentCategory implements Action {
  public readonly type = ECommonActions.GetCurrentCategory;
  constructor(public alias: any) {}
}

export class GetCurrentCategorySuccess implements Action {
  public readonly type = ECommonActions.GetCurrentCategorySuccess;
  constructor(public payload: any) {}
}

export class GetCartInfo implements Action {
  public readonly type = ECommonActions.GetCartInfo;
  constructor() {}
}

export class GetCartInfoSuccess implements Action {
  public readonly type = ECommonActions.GetCartInfoSuccess;
  constructor(public payload: any) {}
}

export type CommonActions =
  | GetUser
  | GetUserSuccess
  | GetTenant
  | GetTenantSuccess
  | GetCategories
  | GetCategoriesSuccess
  | GetTenantContext
  | GetTenantContextSuccess
  | GetCities
  | GetCitiesSuccess
  | GetCurrentCity
  | GetCurrentCitySuccess
  | GetCurrentCategory
  | GetCurrentCategorySuccess
  | GetCartInfo
  | GetCartInfoSuccess
  ;
