<div class="">
    <aside style="width:240px;">
      <div class="side_menu mb-3 mobile-offcanvas" id="main_menu">
        <div class="offcanvas-header p-3">
          <button onclick="closeSideMenu()" type="button" class="back_btn btn-close" id="sideMenuCloseButton" aria-haspopup="true" aria-expanded="false"><span
              class="mr-2"><i class="fa fa-angle-left" aria-hidden="true"></i></span>Назад</button>
        </div>

        <ul class="side_menu__list">
          <ng-template #recursiveList let-categories>
          <li *ngFor="let item of categories; let i = index;" class="side_menu__item" [ngClass]="{'has_child' : item?.childs.length > 0, 'isChild': item.parentId, 'hidden': item.count == 0 && item.isSelectionType == false }">
            <a *ngIf="!item?.isSelectionType"  onclick="" class="side_menu__link visibleMobile mobileMenuLink" [ngClass]="{'has_child_link' : item?.childs.length > 0}">
              <span (click)="goToCatalogPage(item?.alias)" class="side_menu_mobileSpan">{{item?.title}}</span>
              <span onclick="sideMenuToggle(this)" class="side_menu_mobileSpanToggle">&nbsp;</span>
            </a>
            <a *ngIf="!item?.isSelectionType" routerLink="{{'/catalog/' + item?.alias}}"  onclick="" class="side_menu__link visibleDesktop" [ngClass]="{'has_child_link' : item?.childs.length > 0}">
              {{item?.title}}
            </a>
            <a *ngIf="item?.isSelectionType" routerLink="/selection" [queryParams]="{type: item?.alias}"  class="side_menu__link">
              {{item?.title}}
            </a>
            <ul *ngIf="item?.childs.length > 0" class="side_menu__sub">
              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item?.childs }"></ng-container>
            </ul>
          </li>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: categories }"></ng-container>
        <li *ngIf="tenantInfo?.externalConstructorLink" class="side_menu__item">
          <a [href]="tenantInfo?.externalConstructorLink" target="_blank" class="side_menu__link">
           3D-конструктор
          </a>
        </li>
        </ul>




      </div>
    </aside>
  </div>
