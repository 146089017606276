import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-layout',
  templateUrl: './left-layout.component.html',
  styleUrls: ['./left-layout.component.less']
})
export class LeftLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
