<section *ngIf="user">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>

        <h1>Личный кабинет</h1>
        <div *ngIf="!selectedOrder" class="account_information my-4">
          <div class="row">
            <div class="col-xl-3 col-sm-6">
              <div class="account_information__item">
                <h3>Имя</h3>
                <div>{{user.fullName}}</div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="account_information__item">
                <h3>Номер телефона</h3>
                <div>{{user.phoneNumber}}</div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="account_information__item">
                <h3>E-mail</h3>
                <div>{{user.email}}</div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6">
              <div class="account_information__item">
                <h3>Адрес доставки</h3>
                <div>{{user.deliveryAddress}}</div>
              </div>
            </div>
          </div>
        </div>
        <a *ngIf="!selectedOrder" routerLink="/personal-edit" class="link">Отредактировать личные данные</a>
        <div *ngIf="!selectedOrder" class="mt-5">
          <h2>Список заказов</h2>
          <table class="order_table table mt-4">
            <thead>
              <tr>
                <th>Заказ</th>
                <th>Дата</th>
                <th>Количество</th>
                <th>Сумма заказа</th>
                <th>Доставка</th>
                <th>Тип доставки</th>
                <th>Тип оплаты</th>
                <th>Подтверждение</th>
                <th>Оплата</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let order of orders">
                <td class="order_table__num" (click)="this.selectedOrder = order">
                  <a href="javascript:void(0);" title="{{order.composition}}" class="link">{{order.number}}</a>
                </td>
                <td class="order_table__date">{{order.date | date}}</td>
                <td class="order_table__amount" title="{{order.composition}}">{{order.count}}</td>
                <td class="order_table__total">{{order.cost | currency:'':'' | replace:',00':''}} руб.</td>
                <td *ngIf="order.deliveryCost != 0" class="order_table__total">{{order.deliveryCost | currency:'':'' |
                  replace:',00':''}} руб.</td>
                <td *ngIf="order.deliveryCost == 0" class="order_table__total"></td>
                <td class="order_table__amount">{{order.deliveryMethodTitle}}</td>
                <td class="order_table__amount">{{order.paymentMethodTitle}}</td>
                <td class="order_table__amount">{{order.isAccepted ? 'Подтвержден' : 'Ожидает подтверждения'}}</td>
                <td class="order_table__payment">
                  <a *ngIf="order.invoiceUrl && !order.isPaid" target="_blank" href="{{order.invoiceUrl}}"
                    style="margin-right: 3px;" class="link">Счет на оплату</a>
                  <a *ngIf="order.paymentUrl" target="_blank" href="{{order.paymentUrl}}" class="link">
                    Перейти к оплате</a>
                  <span *ngIf="!order.paymentUrl && order.isPaid">Оплачен</span>
                  <span
                    *ngIf="!order.paymentUrl && percentagePayment && order.percentagePayment > 0 && order.percentagePayment < 100">
                    Оплачен частично</span>
                  <span *ngIf="!order.paymentUrl && !order.invoiceUrl && !order.isPaid">Не оплачен</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="selectedOrder" class="mt-5">
          <a (click)="this.selectedOrder = null" href="javascript:void(0);" class="link">Вернуться к списку заказов</a>
          <br>
          <h3>Состав заказа "{{selectedOrder.number}}" от {{selectedOrder.date | date}}</h3>
          <div class="cart_list list_full">
            <div *ngFor="let goodItem of selectedOrder.items" class="cart_item">
              <div class="cart_item__main">
                <div class="cart_item__pic mr-4">
                  <a><img src="{{goodItem.previewImage}}" alt="{{goodItem.title}}" /></a>
                </div>
                <div class="cart_item__content mr-sm-4">
                  <h3>
                    <a>{{goodItem.title}}</a>
                  </h3>
                </div>
              </div>
              <div class="cart_item__details">
                <div class="mx-xl-4">
                  <div class="change_num text-nowrap">
                    {{goodItem.count}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
