<div
  *ngIf="forgotPasswordPopupVisible"
  class="modal show"
  id="authorization_restoreModal"
>
  <div class="modal_overlay"></div>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <button
        (click)="closePopup()"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body p-4">
        <div *ngIf="!isPasswordReset" class="px-sm-5 text-center">
          <h3>Восстановить пароль</h3>
          <div class="mt-5">
            <p *ngIf="errorMessage" style="font-size: 14px; color: red">
              {{ errorMessage }}
            </p>
            <p class="txt_grey">
              Чтобы восстановить пароль, введите адрес вашей эл.почты:
            </p>
            <form class="my-3 mx-auto" style="max-width: 320px">
              <div class="form-group">
                <input #emailInput
                  type="text"
                  class="form-control dark"
                  placeholder="Электронная почта"
                />
              </div>
              <div class="mt-5 mb-3">
                <button [attr.disabled]="isLoading == true ? true : null" (click)="resetPassword(emailInput.value)"

                  type="button"
                  class="btn btn_custom btn_md btn_red"
                  style="width: 150px"
                >
                  Отправить
                </button>
              </div>
              <div>
                <!-- <a href="#" class="link text_sm">Я вспомнил пароль</a> -->
              </div>
            </form>
          </div>
        </div>
        <div *ngIf="isPasswordReset" class="px-sm-5 text-center">
          <p>Проверьте вашу почту.</p>
        </div>
      </div>
    </div>
  </div>
</div>
