import { UserInfoDto } from './../services/main.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { utf8Encode } from '@angular/compiler/src/util';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { selectUserInfo } from 'src/core/store/selectors/common.selector';
import { IAppState } from 'src/core/store/state/app.state';
import {
  DesignProjectDto,
  MainRequestApiClient,
} from '../services/main.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-kitchen-design-project-form',
  templateUrl: './kitchen-design-project-form.component.html',
  styleUrls: ['./kitchen-design-project-form.component.less'],
})
@AutoUnsubscribe()
export class KitchenDesignProjectFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  constructor(
    private _store: Store<IAppState>,
    private _mainRequestApiClient: MainRequestApiClient,
    private cookieService: CookieService
  ) {}

  ngOnDestroy(): void {}

  model = new DesignProjectDto();
  submitted = false;

  submitForm(kitchenForm) {
    console.log(kitchenForm);
    if (kitchenForm.valid) {
      this.model.calltouchSessionId = this.cookieService.get('_ct_session_id');
      this._mainRequestApiClient
        .designProject(this.model)
        .toPromise()
        .then((result) => {
          if (result.success) this.submitted = true;
        });
    }
    else{
      this.markAsTouched(kitchenForm.form);
    }
  }


  markAsTouched(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
  
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } 
      else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectUserInfo)).subscribe((user) => {
        if (user) {
          self.model.phoneNumber = user.phoneNumber;
          self.model.email = user.email;
          self.model.fullName = user.fullName;
        }
      })
    );
  }
}
