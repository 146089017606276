import { SelectionComponent } from './selection/selection.component';
import { ReplacePipe } from './../core/pipes/replace.pipe';
import { RunScriptsDirective } from './../core/pipes/safe-html-run-scripts.directive';
import { SafeHtmlPipe } from './../core/pipes/safe-html.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeRu from '@angular/common/locales/ru';

import {TransferHttpCacheModule} from '@nguniversal/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CatalogFilterComponent } from './catalog-filter/catalog-filter.component';
import { LeftLayoutComponent } from './left-layout/left-layout.component';
import { CatalogLayoutComponent } from './catalog-layout/catalog-layout.component';
import { CatalogSubitemsComponent } from './catalog-subitems/catalog-subitems.component';
import { CatalogTopFilterComponent } from './catalog-top-filter/catalog-top-filter.component';
import { FooterComponent } from './footer/footer.component';
import { CatalogItemsComponent } from './catalog-items/catalog-items.component';
import { MainPageComponent } from './main-page/main-page.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule, UrlSerializer } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { API_BASE_URL } from './services/main.service';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token-interceptor';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { GoodSectionCaruselComponent } from './carusel/carusel.component';
import { NewsCaruselComponent } from './news-carusel/news-carusel.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from 'src/core/store/effects/router.effects';
import { CommonEffects } from 'src/core/store/effects/common.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from 'src/core/store/reducers/app.reducers';
import { MainPageCaruselComponent } from './main-page-carusel/main-page-carusel.component';
import { DisplayHtmlComponent } from './display-html/display-html.component';
import { LocationPopupComponent } from './location-popup/location-popup.component';
import { RetailThemeComponent } from './retail-theme/retail-theme.component';
import { GoodPageComponent } from './good-page/good-page.component';
import { GalleryModule } from 'ng-gallery';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightboxModule } from 'ng-gallery/lightbox';
import { IMPLICIT_REFERENCE } from '@angular/compiler/src/render3/view/util';
import { ReplacePipeModule } from 'src/core/pipes/replace-pipe.module';
import { FormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { KitchenDesignProjectComponent } from './kitchen-design-project/kitchen-design-project.component';
import { KitchenDesignProjectFormComponent } from './kitchen-design-project-form/kitchen-design-project-form.component';
import { CartComponent } from './cart/cart.component';
import { MainPageCategoryComponent } from './main-page-category/main-page-category.component';
import { NgxMaskModule } from 'ngx-mask';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { UrlSlashSerializer } from './url-slash-serializer';
import { CookieModule } from 'ngx-cookie';
import { CarouselModule } from '@marcreichel/angular-carousel';


registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginFormComponent,
    LeftMenuComponent,
    BreadcrumbsComponent,
    CatalogFilterComponent,
    LeftLayoutComponent,
    CatalogLayoutComponent,
    CatalogSubitemsComponent,
    CatalogTopFilterComponent,
    FooterComponent,
    CatalogItemsComponent,
    MainPageComponent,
    NewsListComponent,
    NewsItemComponent,
    GoodSectionCaruselComponent,
    NewsCaruselComponent,
    MainPageCaruselComponent,
    SafeHtmlPipe,
    RunScriptsDirective,
    DisplayHtmlComponent,
    LocationPopupComponent,
    RetailThemeComponent,
    GoodPageComponent,
    SearchComponent,
    SelectionComponent,
    NotFoundComponent,
    ProfileComponent,
    ProfileEditComponent,
    KitchenDesignProjectComponent,
    KitchenDesignProjectFormComponent,
    CartComponent,
    MainPageCategoryComponent,
    ForgotPasswordComponent,
    SitemapComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReplacePipeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CookieModule.forRoot(),
    //TransferHttpCacheModule,
    GalleryModule,
    LightboxModule,
    AppRoutingModule,
    HttpClientModule,
    IvyCarouselModule,
    CarouselModule,
    StoreModule.forRoot(appReducers),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
    }),
    EffectsModule.forRoot([RouterEffects, CommonEffects]),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AuthService,
    HttpClientModule,
    { provide: API_BASE_URL, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'ru-ru' },
    {
      provide: UrlSerializer,
      useClass: UrlSlashSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

