import { CartModel } from './../../core/store/models/cart-model';
import {
  TenantInfoVM,
  CartItemVM,
  CartGoodPreviewCardVM,
} from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor() {}

  addToCartById(id: string, price: number) {
    if (id && price) {
      const currentCart = this.getCurrentCart();

      let existItem = currentCart.items.find((x) => x.id == id);
      if (existItem) {
        existItem.count++;
        existItem.price = price;
      } else {
        currentCart.items.push(new CartItemVM({ id, price, count: 1 }));
      }

      this.saveCart(currentCart);
    }
  }

  removeFromCartByid(id: string) {
    if (id) {
      const currentCart = this.getCurrentCart();

      currentCart.items = currentCart.items.filter((x) => x.id != id);
      this.saveCart(currentCart);
    }
  }

  setCountOfCartItemById(id: string, count: number) {
    if (id) {
      const currentCart = this.getCurrentCart();

      let existItem = currentCart.items.find((x) => x.id == id);
      if (existItem && count > 0) {
        existItem.count = count;
        this.saveCart(currentCart);
      }
    }
  }

  incrCartItemById(id: string) {
    if (id) {
      const currentCart = this.getCurrentCart();

      let existItem = currentCart.items.find((x) => x.id == id);
      if (existItem) {
        existItem.count++;
        this.saveCart(currentCart);
      }
    }
  }

  decrCartItemById(id: string) {
    if (id) {
      const currentCart = this.getCurrentCart();

      let existItem = currentCart.items.find((x) => x.id == id);
      if (existItem && existItem.count > 1) {
        existItem.count--;
        this.saveCart(currentCart);
      }
    }
  }

  getCurrentCart(): Cart {
    let cart = localStorage.getItem('cart');
    if (cart) {
      return JSON.parse(cart);
    }
    return new Cart();
  }

  saveCart(cart: Cart) {
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  deleteDeprecated(cartItems: CartGoodPreviewCardVM[]) {
    let cart = this.getCurrentCart();
    let ids = cartItems.map((x) => x.good.id);
    let cartItemsBeforeCount = cart.items.length;
    cart.items = cart.items.filter((x) => ids.some((p) => p == x.id));
    this.saveCart(cart);

    return cartItems.length != cartItemsBeforeCount
  }

  getCartInfo(): CartModel {
    const cartInfo = this.getCurrentCart();
    if (cartInfo) {
      let cost = 0;
      let count = 0;
      cartInfo.items.forEach((element) => {
        count = count + element.count;
        cost = cost + element.count * element.price;
      });

      return new CartModel(count, cost);
    }
    return new CartModel(0, 0);
  }

  clearCart() {
    this.saveCart(new Cart());
  }
}

export class Cart {
  constructor() {
    this.items = [];
  }
  items: CartItemVM[];
}
