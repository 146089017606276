<section class="error_wrap">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="py-lg-5 py-3">
          <div class="error_block">
            <div class="error_block_zzz">
              <img src="../../assets/images/404-image.png" alt="not found" />
              <div class="zzz">
                <div class="zzz__1">
                  <img src="../../assets/images/zzz-1.svg" alt="not found" />
                </div>
                <div class="zzz__2">
                  <img src="../../assets/images/zzz-2.svg" alt="not found" />
                </div>
                <div class="zzz__3">
                  <img src="../../assets/images/zzz-3.svg" alt="not found" />
                </div>
                <div class="zzz__4">
                  <img src="../../assets/images/zzz-4.svg" alt="not found" />
                </div>
                <div class="zzz__5">
                  <img src="../../assets/images/zzz-5.svg" alt="not found" />
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <h1>Извините, страница не найдена</h1>
            <div class="mt-5 my-lg-0 pt-3">
              <button routerLink="/" class="btn btn_custom btn_xmd btn_red">На главную</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
