import {
  selectCurrentCategory,
  selectCurrentCity,
} from '../../core/store/selectors/common.selector';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Subscription } from 'rxjs';
import { IAppState } from 'src/core/store/state/app.state';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SeoForPageVM } from './main.service';

@Injectable({
  providedIn: 'root',
})
@AutoUnsubscribe()
export class SeoService implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  constructor(
    @Inject(DOCUMENT) private doc,
    private _store: Store<IAppState>,
    private meta: Meta,
    private titleService: Title
  ) {}

  ngOnDestroy(): void {}

  setupSeo(seoResult: SeoForPageVM) {
    if (seoResult.title) this.setTitle(seoResult.title);
    if (seoResult.description) this.updateTag('description', seoResult.description);
    let categoryCrisper = this.doc.getElementById('catalogFooterDescription');
    if (categoryCrisper) categoryCrisper.innerHTML = seoResult.categoryText;
    this.updateMainPageSeoText(seoResult.mainPageText);
    this.createLinkForCanonicalURL(seoResult.canonical);
  }

  updateTag(tag, value) {
    this.meta.updateTag({
      name: tag,
      content: value,
    });
  }

  setTitle(title) {
    this.titleService.setTitle(title);
  }

  updateMainPageSeoText(text) {
    let crisperText =  this.doc.getElementById('mainPageSeoBlockText')
    let crisper =  this.doc.getElementById('mainPageSeoBlock')
    if (text) {
      if(crisperText) crisperText.innerHTML = text;
    } else {
      if(crisper) crisper.innerHTML = null;
    }
  }

  createLinkForCanonicalURL(url?: string) {
    let links = this.doc.getElementsByTagName('link');
    links[1].setAttribute('href', url ?? this.doc.URL);
  }
}
