import { ThrowStmt } from '@angular/compiler';

export class IBreadCrumb {
  label: string;
  url: string;

  constructor(label: string, url: string) {
    this.label = label;
    this.url = url;
  }
}
