<!-- <carousel *ngIf="!isEmpty && !prerenderMode" [height]="280" [cellsToShow]="1" [cellsToScroll]="1" [loop]="true" [autoplay]="true" [autoplayInterval]="5000"
  [dots]="true" [arrows]="true" [arrowsOutside]="true">
  <div *ngFor="let item of items" class="carousel-cell">
    <a routerLink="{{'/' + item?.url }}">
      <img [src]="item?.path" alt="Баннер">
    </a>
  </div>
</carousel> -->

<angular-carousel *ngIf="!isEmpty && !prerenderMode" [infinite]="true" [auto]="true" [duration]="5000">
  <ng-container  *ngFor="let item of items">
    <div *angularCarouselSlide class="carousel-cell">
      <a routerLink="{{'/' + item?.url }}">
        <img [src]="item?.path" alt="Баннер">
      </a>
    </div>
  </ng-container>

</angular-carousel>


<div *ngIf="!isEmpty && prerenderMode">
  <div *ngFor="let item of items" class="carousel-cell">
    <a routerLink="{{'/' + item?.url }}">
      <img [src]="item?.path" alt="Баннер">
    </a>
  </div>
</div>
