import { selectUserInfo } from 'src/core/store/selectors/common.selector';
import {
  UserInfoDto,
  MainAccountApiClient,
  UserProfileVM,
} from './../services/main.service';
import { IAppState } from 'src/core/store/state/app.state';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GetUser } from 'src/core/store/actions/common.actions';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.less'],
})
@AutoUnsubscribe()
export class ProfileEditComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  user: UserInfoDto;
  validationMessage: string;
  public email: string;
  public phoneNumber: string;
  public deliveryAddress: string;
  public fullName: string;
  public password: string;
  public confirmPassword: string;
  constructor(
    private _store: Store<IAppState>,
    private _mainAccountApiClient: MainAccountApiClient,
    private router: Router
  ) {}

  ngOnDestroy(): void {}

  save() {
    const self = this;
    this.validationMessage = null;
    const userInfoDto = new UserProfileVM();
    userInfoDto.username = self.user.username;
    userInfoDto.fullName = self.fullName;
    userInfoDto.deliveryAddress = self.deliveryAddress;
    userInfoDto.email = self.email;
    userInfoDto.phoneNumber = self.phoneNumber;
    userInfoDto.password = self.password;
    userInfoDto.confirmPassword = self.confirmPassword;

    this._mainAccountApiClient
      .edit(userInfoDto)
      .toPromise()
      .then((result) => {
        if (!result.success) self.validationMessage = result.message;
        else {
          this._store.dispatch(new GetUser());
          this.router.navigate(['/personal']);
        }
      });
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectUserInfo)).subscribe((userInfo) => {
        if (userInfo) {
          self.user = userInfo;
          this.fullName = userInfo.fullName;
          this.deliveryAddress = userInfo.deliveryAddress;
          this.email = userInfo.email;
          this.phoneNumber = userInfo.phoneNumber;
        }
      })
    );
  }
}
