<div class="container" *ngIf="items && items.length > 0">
  <div class="row">
    <div class="col-12 text-center">
      <h2 [routerLink]="'/news'"><a href="javascript:void(0);">Наши новости</a></h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="cards_slider slider_v1">
        <carousel *ngIf="items && !prerenderMode" [height]="450" [cellWidth]="350" [cellsToScroll]="1" [loop]="true"
          [arrowsOutside]="true" [overflowCellsLimit]="100">
          <div *ngFor="let item of items" class="carousel-cell">
            <div class="news_card">
              <div class="news_card__pic">
                <a *ngIf="!item.alias.includes('http')" routerLink="{{'news/' + item.alias}}"><img [src]="item.image"
                    alt="{{item.header}}"></a>
                <a *ngIf="item.alias.includes('http')" href="{{item.alias}}" target="_blank"><img [src]="item.image"
                    alt="{{item.header}}"></a>
              </div>
              <h3 *ngIf="!item.alias.includes('http')"><a routerLink="{{'/news/' + item.alias}}">{{item.header}}</a>
              </h3>
              <h3 *ngIf="item.alias.includes('http')"><a href="{{item.alias}}" target="_blank">{{item.header}}</a></h3>
              <div class="news_card__text txt_grey">
                {{(item.content | slice:0:180) + '...'}}
              </div>
              <div class="news_card__bottom">
                <a *ngIf="!item.alias.includes('http')" routerLink="{{'news/' + item.alias}}" class="link">Подробнее<span class="ml-2"><i
                      class="fa fa-angle-right" aria-hidden="true"></i></span></a>
                <a *ngIf="item.alias.includes('http')" href="{{item.alias}}" target="_blank" class="link">Перейти<span class="ml-2"><i
                      class="fa fa-angle-right" aria-hidden="true"></i></span></a>
              </div>
            </div>
          </div>
        </carousel>
        <div *ngIf="items && prerenderMode">
          <div *ngFor="let item of items" class="carousel-cell">
            <div class="news_card">
              <div class="news_card__pic">
                <a routerLink="{{'news/' + item.alias}}"><img [src]="item.image" alt="{{item.header}}"></a>
              </div>
              <h3><a routerLink="{{'news/' + item.alias}}">{{item.header}}</a></h3>
              <div class="news_card__text txt_grey">
                {{(item.content | slice:0:180) + '...'}}
              </div>
              <div class="news_card__bottom">
                <a routerLink="{{'news/' + item.alias}}" class="link">Подробнее<span class="ml-2"><i
                      class="fa fa-angle-right" aria-hidden="true"></i></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>