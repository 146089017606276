import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router"

export class UrlSlashSerializer implements UrlSerializer {

    public parse(url: any): UrlTree {
        const dus = new DefaultUrlSerializer()
        return dus.parse(url)
    }

    public serialize(tree: UrlTree): string {
        const dus = new DefaultUrlSerializer()
        const path = dus.serialize(tree)

        const splitOn = path.indexOf("?") > - 1 ? "?" : "#"
        const pathArr = path.split(splitOn)
        pathArr[0] += "/"

        if (pathArr[0] === "//")
        {
            pathArr[0] = "/"
        }

        return pathArr.join(splitOn)
    }
}
