import { Title } from '@angular/platform-browser';
import { selectRouter } from './../../core/store/selectors/router.selector';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { combineLatest, Subscription } from 'rxjs';
import { IAppState } from 'src/core/store/state/app.state';
import { IBreadCrumb } from './breadcrumb.interface';
import {
  selectCategories,
  selectCategoriesList,
} from 'src/core/store/selectors/common.selector';
import { CategoryItemVM } from '../services/main.service';
import { GetCurrentCategory } from 'src/core/store/actions/common.actions';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.less'],
})
@AutoUnsubscribe()
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  public breadcrumbs: IBreadCrumb[];
  categories: CategoryItemVM[];
  subscriptions: Subscription = new Subscription();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _store: Store<IAppState>
  ) { }

  ngOnInit(): void {
    let self = this;
    const $routerState = this._store.pipe(
      select((appState) => appState.router)
    );
    const $categories = this._store.pipe(select(selectCategoriesList));

    combineLatest([$routerState, $categories]).subscribe(
      ([routerState, categories]) => {
        if (routerState && categories && categories.length > 0) {
          this.categories = categories;
          self.build(routerState.state.root.children[0]);
        }
      }
    );
  }

  ngOnDestroy(): void { }

  build(urlNode: ActivatedRouteSnapshot) {
    let self = this;
    this.breadcrumbs = [];
    this.pushBreadcrumb('', 'Главная');
    let urls = urlNode.url;
    let isCatalogPage = urls.some((x) => x.path == 'catalog');
    urls.forEach((url, index, arr) => {
      let element = self.breadsMatching.find((x) => x.url == url.path);
      if (element) {
        this.pushBreadcrumb(element.url, element.title);
      } else {
        if (isCatalogPage && urls.length > 2 && index === arr.length - 2) {
          const existCategory = self.categories.find(
            (x) => x.alias == url.path
          );

          if (existCategory) {
            self._store.dispatch(new GetCurrentCategory(existCategory.alias));
            let allList = self.findAllParents(existCategory);
            for (const category of allList) {
              this.pushBreadcrumb(
                category.alias,
                category.title,
                'catalog/'
              );
            }
            // const parentCategory = self.categories.find(
            //   (x) => x.id == existCategory.parentId
            // );
            // if (parentCategory) {
            //   this.pushBreadcrumb(
            //     parentCategory.alias,
            //     parentCategory.title,
            //     'catalog/'
            //   );
            // }
            // this.pushBreadcrumb(
            //   existCategory.alias,
            //   existCategory.title,
            //   'catalog/'
            // );
          }
        }
        if(isCatalogPage && urls.length == 2 && index === 1){
          const existCategory = self.categories.find(
            (x) => x.alias == url.path
          );
          if(existCategory){
            let allList = self.findAllParents(existCategory);
            for (const category of allList) {
              this.pushBreadcrumb(
                category.alias,
                category.title,
                'catalog/'
              );
            }
          }
        }
      }
    });
  }


  findAllParents(category: CategoryItemVM) {
    let result: CategoryItemVM[] = [];
    result.push(category);
    this.addParentOrBreak(category, result);

    return result.reverse();
  }

  addParentOrBreak(category: CategoryItemVM, result:CategoryItemVM[]){
    if(category.parentId){
      let parentCategory = this.categories.find(x => x.id == category.parentId);
      if(parentCategory){
        result.push(parentCategory);
        this.addParentOrBreak(parentCategory, result);
      }
    }
  }

  pushBreadcrumb(alias: string, title: string, prefix: string = '') {
    let path = '';
    switch (alias) {
      case 'catalog':
        path = `/${alias}`;
        break;

      default:
        path = prefix !== '' ? `/${prefix + alias}` : alias;
        break;
    }


    let breadcrumb = new IBreadCrumb(title, path);
    this.breadcrumbs.push(breadcrumb);
  }

  breadsMatching = [
    {
      url: 'news',
      title: 'Новости',
    },
    {
      url: 'catalog',
      title: 'Каталог',
    },
    {
      url: 'personal-edit',
      title: 'Мой кабинет'
    },
    {
      url: 'search',
      title: 'Поиск',
    },
    {
      url: 'order',
      title: 'Оформление заказа',
    },
    {
      url: 'dostavka',
      title: 'Доставка',
    },
    {
      url: '3d-project',
      title: 'Заявка на бесплатный дизайн-проект кухни'
    },
    {
      url: 'privacy-policy',
      title: 'Политика конфидециальности',
    },
    {
      url: 'terms-of-use',
      title: 'Условия передачи информации',
    },
    {
      url: 'o_kompanii',
      title: 'О компании',
    },
    {
      url: 'reviews',
      title: 'Отзывы',
    },
    {
      url: 'garantyy',
      title: 'Гарантии',
    },
    {
      url: 'rekvizity',
      title: 'Реквизиты',
    },
    {
      url: 'vozvrat',
      title: 'Обмен и возврат',
    },
    {
      url: 'contact',
      title: 'Контакты',
    },
  ];
}
