import { selectCities } from './../../core/store/selectors/common.selector';
import { GetCurrentCity } from './../../core/store/actions/common.actions';
import { TenantCityVM } from './../services/main.service';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/core/store/state/app.state';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'app-location-popup',
  templateUrl: './location-popup.component.html',
  styleUrls: ['./location-popup.component.less'],
})
export class LocationPopupComponent implements OnInit, OnDestroy  {
  subscriptions: Subscription = new Subscription();
  locationPopupVisible = false;
  currentCity: TenantCityVM;
  cities: TenantCityVM[];
  constructor( @Inject(DOCUMENT) private doc, private location: Location, private _store: Store<IAppState>, private router: Router) {}
  ngOnDestroy(): void {

  }

  closeLocationPopup() {
    this.locationPopupVisible = false;
  }

  ngOnInit(): void {
    this.startup();
  }

  redirect(host:string){
    let url = this.router.url;
    this.doc.location.href = host + url;
  }

  async startup(){
    this.subscriptions.add(
      this._store.pipe(select(selectCities)).subscribe((cities) => {
        this.cities = cities;
        if (cities) {
          this.currentCity = cities.find((x) => x.host == location.host);
          if (this.currentCity) {
            this._store.dispatch(new GetCurrentCity(this.currentCity?.cityId));
          }
        }
      })
    );
  }
}
