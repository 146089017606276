<section>
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>
      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <h1>Результаты поиска по запросу "{{searchQuery}}"</h1>
        <div>
          <app-catalog-top-filter [isSearchPage]="true" (changeSortTypeEvent)="changeSortType($event)" (changeTakeCountEvent)="changeTakeCount($event)"></app-catalog-top-filter>
          <app-catalog-items [searchQuery]="searchQuery" [sortType]="sortType" [take]="takeCount"></app-catalog-items>
        </div>
      </div>
    </div>
  </div>
</section>
