import { CategoryItemVM } from './../services/main.service';
import { selectCurrentCategory } from './../../core/store/selectors/common.selector';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/core/store/state/app.state';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
@AutoUnsubscribe()
export class SearchComponent implements OnInit, OnDestroy, OnChanges {
  categories: CategoryItemVM[];
  currentCategory: CategoryItemVM;
  takeCount: number = 24;
  sortType: string;
  searchQuery:string;
  subscriptions: Subscription = new Subscription();
  constructor(
    private _store: Store<IAppState>,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {}

  changeTakeCount(value) {
    this.takeCount = value;
  }

  changeSortType(value) {
    this.sortType = value;
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectCurrentCategory)).subscribe((result) => {
        if (result) {
          self.currentCategory = result;
        }
      })
    );

    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        const searchQuery = params['q'];
        self.searchQuery = searchQuery;
      })
    );
  }

  ngOnChanges(): void {}
}
