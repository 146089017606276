<div *ngIf="locationPopupVisible && currentCity" class="modal show" id="locationModal">
  <div class="modal_overlay"></div>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header align-items-center px-4">
        <h3 class="m-2">Ваш город: {{currentCity?.title}}</h3>
        <button (click)="closeLocationPopup()" type="button" class="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="lblue_bg px-4 py-3">
          <p>Неправильно определили? Выберите из списка:</p>
          <div class="search_form mb-1">
            <input type="text" placeholder="Найти регион" name="q" class="form-control">
            <button type="submit" class="search_btn">
              <span class="icon-header-search-icon"></span>
            </button>
          </div>
        </div>
        <div class="p-4">
          <!-- <ul class="list-inline list_ThreeCol">
            <li>
              <a href="#"><strong>Москва</strong></a>
            </li>
            <li>
              <a href="#"><strong>Новосибирск</strong></a>
            </li>
            <li>
              <a href="#"><strong>Республика Саха (Якутия)</strong></a>
            </li>
          </ul> -->
          <ul class="list-inline list_ThreeCol my-3 txt_light">
            <li *ngFor="let item of cities">
              <a href="javascript:void(0);" (click)="redirect(item.redirectUrl)" >{{item.title}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
