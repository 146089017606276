import { selectCategoriesList } from './../selectors/common.selector';
import { GetCurrentCategory } from './../actions/common.actions';
import { selectCategories, selectTenantContextInfo } from 'src/core/store/selectors/common.selector';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, scan, tap } from 'rxjs/operators';

import { IAppState } from '../state/app.state';

import { routerNavigationAction } from '@ngrx/router-store';
import { PlatformLocation } from '@angular/common';

@Injectable()
export class RouterEffects {
  constructor(private _actions$: Actions, private _store: Store<IAppState>, private platformLocation: PlatformLocation) {}

  @Effect({ dispatch: true })
  routeCategoryChange$ = this._actions$.pipe(
    ofType(routerNavigationAction),
    map((action: any) => action.payload),
    withLatestFrom(this._store.pipe(select(selectCategories)), this._store.pipe(select(selectTenantContextInfo))),
    switchMap(([routerNavigationData]) => {
      const categoryAlias =
        routerNavigationData.routerState.root.firstChild?.params['category'];
      if (categoryAlias) {
        this._store.dispatch(new GetCurrentCategory(categoryAlias));
      }
      else{
        this._store.dispatch(new GetCurrentCategory(null));
      }
      return of();
    })
  );
}
