<section [class]="sectionClass" *ngIf="!isEmpty">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h2>{{title}}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="cards_slider slider_v1">
          <carousel *ngIf="items && !prerenderMode" [height]="320"  [cellsToScroll]="1" [loop]="true"
            [arrowsOutside]="true" [overflowCellsLimit]="100">
            <div *ngFor="let item of items" class="carousel-cell">
              <div class="item_card">
                <div class="item_card__inner">
                  <div class="item_labels">
                    <!-- <div class="item_label new">Новинка</div> -->
                    <div *ngIf="type == 'hot'" class="item_label hit">Хит</div>
                  </div>
                  <div class="item_card__pic">
                    <a routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias}}"><img [src]="(item.previewImage)" alt="{{item.title}}"></a>
                  </div>
                  <p class="title"><a routerLink="{{'/catalog/'+ item.categoryAlias + '/' + item.alias}}">{{item.title}}</a></p>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="old_price mx-2" *ngIf="item.price != item.oldPrice">
                      {{item.oldPrice  | currency:'':'' | replace:',00':''}} руб.
                    </p>
                    <p class="current_price mx-2">
                      <span class="bigger">{{item.price  | currency:'':'' | replace:',00':''}}</span> руб.
                    </p>
                  </div>
                  <div class="item_card__bottom">
                    <a href="javascript:void(0);" (click)="addToCart(item?.id, item?.price)" class="btn btn_custom btn_md btn_red w-100 add_to_cart_button">
                      <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span
                            class="path2"></span></span></span>
                      В корзину
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
<div *ngIf="items && prerenderMode">
          <div *ngFor="let item of items" class="carousel-cell">
            <div class="item_card">
              <div class="item_card__inner">
                <div class="item_labels">
                  <!-- <div class="item_label new">Новинка</div> -->
                  <div *ngIf="type == 'hot'" class="item_label hit">Хит</div>
                </div>
                <div class="item_card__pic">
                  <a routerLink="{{'/catalog/' + item.categoryAlias + '/' + item.alias}}"><img [src]="(removeWatermarkFromImage(item.previewImage))" alt="{{item.title}}"></a>
                </div>
                <p class="title"><a routerLink="{{'/catalog/'+ item.categoryAlias + '/' + item.alias}}">{{item.title}}</a></p>
                <div class="d-flex align-items-center justify-content-center">
                  <p class="old_price mx-2" *ngIf="item.price != item.oldPrice">
                    {{item.oldPrice  | currency:'':'' | replace:',00':''}} руб.
                  </p>
                  <p class="current_price mx-2">
                    <span class="bigger">{{item.price  | currency:'':'' | replace:',00':''}}</span> руб.
                  </p>
                </div>
                <div class="item_card__bottom">
                  <a href="javascript:void(0);" (click)="addToCart(item?.id, item?.price)" class="btn btn_custom btn_md btn_red w-100 add_to_cart_button">
                    <span class="bigger"><span class="icon-header-cart-icon mr-2"><span class="path1"></span><span
                          class="path2"></span></span></span>
                    В корзину
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
