import { CartModel } from './../models/cart-model';
import {
  selectCartInfo,
  selectCategories,
  selectCategoriesList,
} from './../selectors/common.selector';
import {
  ECommonActions,
  GetCartInfo,
  GetCartInfoSuccess,
  GetCategories,
  GetCategoriesSuccess,
  GetCities,
  GetCitiesSuccess,
  GetCurrentCategory,
  GetCurrentCategorySuccess,
  GetCurrentCity,
  GetCurrentCitySuccess,
  GetTenant,
  GetTenantContext,
  GetTenantContextSuccess,
  GetTenantSuccess,
  GetUser,
  GetUserSuccess,
} from './../actions/common.actions';
import {
  MainAccountApiClient,
  MainAuthApiClient,
  MainCategoryApiClient,
  MainTenantApiClient,
} from './../../../app/services/main.service';
import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';

import { IAppState } from '../state/app.state';
import { CartService } from 'src/app/services/cart.service';

@Injectable()
export class CommonEffects {
  constructor(
    private _mainAuthApiClient: MainAuthApiClient,
    private _mainAccountApiClient: MainAccountApiClient,
    private _mainTenantApiClient: MainTenantApiClient,
    private _mainCategoryApiClient: MainCategoryApiClient,
    private _cartService: CartService,
    private _actions$: Actions,
    private _store: Store<IAppState>
  ) {}

  @Effect({ dispatch: true })
  getUser$ = this._actions$.pipe(
    ofType<GetUser>(ECommonActions.GetUser),
    map((action) => action),
    switchMap(() => {
      return this._mainAccountApiClient.getUser();
    }),
    switchMap((result: any) => {
      if (result instanceof GetUserSuccess) {
        return of(new GetUserSuccess(null));
      } else {
        return of(new GetUserSuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getTenant$ = this._actions$.pipe(
    ofType<GetTenant>(ECommonActions.GetTenant),
    map((action) => action),
    switchMap(() => {
      return this._mainTenantApiClient.getInfo();
    }),
    switchMap((result: any) => {
      if (result instanceof GetTenantSuccess) {
        return of(new GetTenantSuccess(null));
      } else {
        return of(new GetTenantSuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getTenantContext$ = this._actions$.pipe(
    ofType<GetTenantContext>(ECommonActions.GetTenantContext),
    map((action) => action),
    switchMap(() => {
      return this._mainTenantApiClient.getFullInfo();
    }),
    switchMap((result: any) => {
      if (result instanceof GetTenantContextSuccess) {
        return of(new GetTenantContextSuccess(null));
      } else {
        return of(new GetTenantContextSuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getCategories$ = this._actions$.pipe(
    ofType<GetCategories>(ECommonActions.GetCategories),
    map((action) => action),
    switchMap(() => {
      return this._mainCategoryApiClient.getWithCount();
    }),
    switchMap((result: any) => {
      if (result instanceof GetCategoriesSuccess) {
        return of(new GetCategoriesSuccess(null));
      } else {
        return of(new GetCategoriesSuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getCities$ = this._actions$.pipe(
    ofType<GetCities>(ECommonActions.GetCities),
    map((action) => action),
    switchMap(() => {
      return this._mainTenantApiClient.getCities();
    }),
    switchMap((result: any) => {
      if (result instanceof GetCitiesSuccess) {
        return of(new GetCitiesSuccess(null));
      } else {
        return of(new GetCitiesSuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getCurrentCity$ = this._actions$.pipe(
    ofType<GetCurrentCity>(ECommonActions.GetCurrentCity),
    withLatestFrom(this._store),
    //map(([action, storeState]) => {action.id, storeState.common.cities}),
    switchMap(([action, storeState]) => {
      const city = storeState.common.cities.find((x) => x.cityId === action.id);
      return of(city);
    }),
    switchMap((result: any) => {
      if (result instanceof GetCurrentCitySuccess) {
        return of(new GetCurrentCitySuccess(null));
      } else {
        return of(new GetCurrentCitySuccess(result));
      }
    })
  );

  @Effect({ dispatch: true })
  getCurrentCategory$ = this._actions$.pipe(
    ofType<GetCurrentCategory>(ECommonActions.GetCurrentCategory),
    withLatestFrom(this._store.select(selectCategoriesList)),
    switchMap(([action, categories]) => {
      if (!action.alias) {
        return of(undefined);
      }
      if (!categories || categories.length == 0) {
        return of(undefined);
      }
      let alias = action.alias.split('?')[0];
      alias = alias.replace("/","");
      const category = categories.find((x) => x.alias === alias);
      if(!category)
        return of(null);

      return of(category);
    }),
    switchMap((result: any) => {
              if (result instanceof GetCurrentCategorySuccess) {
          return of(new GetCurrentCategorySuccess(null));
        } else {
          return of(new GetCurrentCategorySuccess(result));
        }
    })
  );

  @Effect({ dispatch: true })
  getCartInfo$ = this._actions$.pipe(
    ofType<GetCartInfo>(ECommonActions.GetCartInfo),
    map((action) => action),
    switchMap(() => {
      const cartInfo = this._cartService.getCartInfo();
      return of(cartInfo);
    }),
    switchMap((result: any) => {
      if (result instanceof GetCartInfoSuccess) {
        return of(new GetCartInfoSuccess(null));
      } else {
        return of(new GetCartInfoSuccess(result));
      }
    })
  );
}
