import { CartModel } from '../models/cart-model';
import {
  UserInfoDto,
  TenantInfoVM,
  CategoryItemVM,
  TenantCityVM,
} from './../../../app/services/main.service';

export interface ICommonState {
  user: UserInfoDto;
  tenant: TenantInfoVM;
  categories: CategoryItemVM[];
  categoriesList: CategoryItemVM[];
  currentCategory: CategoryItemVM;
  cities: TenantCityVM[];
  currentCity: TenantCityVM;
  cartInfo: CartModel;
}

export const initialCommonState: ICommonState = {
  user: null,
  tenant: undefined,
  categories: [],
  categoriesList: [],
  currentCategory: null,
  cities: [],
  currentCity: null,
  cartInfo: new CartModel(0, 0),
};
