<section *ngIf="html">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="content">
          <div [innerHTML]="html | safeHtml" runScripts></div>
        </div>
      </div>
    </div>
  </div>
</section>
