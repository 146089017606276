import { Router } from '@angular/router';
import { GetCartInfo } from './../../core/store/actions/common.actions';
import {
  GoodApiClient,
  FilterModelVM,
  GoodPreviewCardVM,
  CatalogFiltersVM,
} from './../services/main.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCurrentCategory } from 'src/core/store/selectors/common.selector';
import { Subscription } from 'rxjs';

import { CategoryItemVM, MainGoodApiClient } from '../services/main.service';
import { IAppState } from 'src/core/store/state/app.state';
import { CartService } from '../services/cart.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-catalog-items',
  templateUrl: './catalog-items.component.html',
  styleUrls: ['./catalog-items.component.less'],
})
export class CatalogItemsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchQuery: string;
  @Input() take: number;
  @Input() sortType: string;
  @Input() selectionType: string;
  @Input() advancedFilters: CatalogFiltersVM;
  @Output() filterModelEvent = new EventEmitter<CatalogFiltersVM>();
  @ViewChild('catalogListAnchor') catalogListAnchor: ElementRef;
  subscriptions: Subscription = new Subscription();
  currentGoods: GoodPreviewCardVM[];
  category: CategoryItemVM;
  currentPage: number = 1;
  pageCount: number = 1;
  isInited: boolean = false;
  pageItems: any[];

  constructor(
    private _store: Store<IAppState>,
    private goodApiClient: MainGoodApiClient,
    private cartService: CartService,
    private router: Router
  ) {}
  ngOnDestroy(): void {}

  addToCart(id: string, price: number) {
    this.cartService.addToCartById(id, price);
    this._store.dispatch(new GetCartInfo());
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store
        .pipe(select(selectCurrentCategory))
        .subscribe((currentCategory) => {
          if (currentCategory || this.router.url == '/catalog') {
            self.category = !currentCategory ? null : currentCategory;
            this.build(null, true);
            this.isInited = true;
          } else {
            if (!currentCategory && (self.searchQuery || self.selectionType)) {
              this.build(null, true);
              this.isInited = true;
            } else {
              if (
                currentCategory === null &&
                !self.searchQuery &&
                !self.selectionType
              )
                this.router.navigate(['/not-found']);
            }
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isInited) {
      if (changes['selectionType']) this.build(null, true);
      else this.build(null, false);
    }
  }

  pageClicked(pageNumber: any) {
    if (!isNaN(pageNumber)) {this.build(pageNumber, false); this.slideToTop();}
  }

  pageDecr() {
    if (this.currentPage !== 1) {
      this.currentPage--;
      this.build(this.currentPage, false);
      this.slideToTop();
    }
  }


  pageIncr() {
    if (this.currentPage !== this.pageCount) {
      this.currentPage++;
      this.build(this.currentPage, false);
      this.slideToTop();
    }
  }
  

  slideToTop(){
    this.catalogListAnchor.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  build(page: number, filterNeeded: boolean) {
    const self = this;
    const filterModel = new FilterModelVM();
    filterModel.page = page ? page : 0;
    filterModel.sortType = self.sortType;
    filterModel.take = self.take;
    filterModel.categoryItemVm = self.category;
    filterModel.searchQuery = self.searchQuery;
    filterModel.selectionType = self.selectionType;
    filterModel.advancedFilters = self.advancedFilters;

    if (filterNeeded)
      self.filterModelEvent.emit(
        new CatalogFiltersVM({
          filtersNeeded: false,
        })
      );
    self.goodApiClient
      .getList(filterNeeded, filterModel)
      .toPromise()
      .then((result) => {
        self.currentGoods = result.goods;
        self.currentPage = result.currentPage;
        self.pageCount = result.pageCount;
        self.buildPager(self.currentPage, self.pageCount);
       // self.subscriptions.unsubscribe();
        if (filterNeeded) self.filterModelEvent.emit(result.filterModel);
      });
  }

  buildPager(page: number, pageCount: number) {
    const self = this;

    let pageItems = new Array<any>();
    for (let i = 1; i <= pageCount; i++) {
      let isHidden = true;
      let pageItem = {
        value: i,
        isHidden: true,
        isActive: false,
        isPlug: false,
      };
      if (pageCount < 9) {
        isHidden = false;
      } else {
        let bottomEdge = page - 3;
        let topEdge = page + 3;
        if ((i > bottomEdge && i <= page) || (i < topEdge && i >= page))
          isHidden = false;
        if (i == 1 || i == pageCount) isHidden = false;
        if (i == bottomEdge || i == topEdge) pageItem.isPlug = true;
      }
      pageItem.isHidden = isHidden;
      if (i == page) pageItem.isActive = true;

      pageItems.push(pageItem);
    }

    self.pageItems = pageItems;
  }
}
