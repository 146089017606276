
<!-- <app-login-form></app-login-form> -->
<header>
  <div class="header_top py-2">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex">
            <div class="header_location" *ngIf="currentCityName && cities && cities.length > 1">
              <a href="javascript:void(0);" (click)="openLocationPopup()" class="text-nowrap modal-trigger d-flex align-items-center" data-target="locationModal">
                <span class="icon-header-location-icon text_red mr-2"><span class="path1"></span><span class="path2"></span></span>
                <span class="border_dashed header_location__name" *ngIf="currentCityName">
                  {{ currentCityName }}
                </span>
              </a>
            </div>
            <ul class="list-inline ml-auto mb-0 d-none d-lg-block">
              <li *ngIf="tenantContext?.deliveryPageInnerHtml" class="list-inline-item mx-3">
                <a routerLink="/dostavka">Доставка и оплата</a>
              </li>
              <li *ngIf="tenantContext?.contactPageInnerHtml" class="list-inline-item mx-3">
                <a routerLink="/contact">Контакты</a>
              </li>
              <li  *ngIf="tenantContext?.aboutPageInnerHtml"class="list-inline-item mx-3">
                <a routerLink="/o_kompanii">О компании</a>
              </li>
              <li *ngIf="tenantContext?.rewiewsPageInnerHtml" class="list-inline-item mx-3">
                <a routerLink="/reviews">Отзывы</a>
              </li>
            </ul>
            <div class="authorization">
              <a *ngIf="!user" href="javascript:void(0)" (click)="openLoginPopup()" class="modal-trigger" data-target="enterModal">
                <span class="icon-header-authorization-icon"><span class="path1"></span><span class="path2"></span></span>
                Войти
              </a>
              <a routerLink="/personal" *ngIf="user" href="javascript:void(0)">
                <span class="icon-header-authorization-icon"><span class="path1"></span><span class="path2"></span></span>
                {{user.email}}
              </a>
              <a style="margin-left: 20px;" *ngIf="user" href="javascript:void(0)" (click)="logout()">
               Выйти
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header_main py-3 py-lg-4">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center flex-wrap flex-lg-nowrap">
            <div class="mr-md-5 mr-auto">
              <a href="#" class="logo">
                <img src="{{ tenantInfo?.logo }}" alt="" width="234" />
              </a>
            </div>
            <div class="mr-md-5">
              <a
                href="tel:{{ tenantInfo?.phoneNumber }}"
                class="contact_phone text-nowrap"
                ><span class="icon-header-phone-icon mr-2"></span
                ><span class="bigger">{{ tenantInfo?.phoneNumber }}</span></a
              >
              <p class="contact_time mb-0 d-none d-md-block">
                {{ tenantInfo?.workSchedule }}
              </p>
            </div>
            <button onclick="toggleSideMenu()"
              class="navbar-toggler d-block d-lg-none ml-auto"
              type="button"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div
              class="d-flex flex-grow-1 header_main__bottom align-items-center"
            >
              <div class="flex-grow-1">
                <form action="javascript:void(0);" class="search_form">
                  <input #searchInCatalogInput (keydown)="searchInCatalogInputonKeyDown($event, searchInCatalogInput.value)"
                    type="text"
                    placeholder="Поиск по каталогу"
                    name="q"
                    class="form-control"
                  />
                  <button (click)="searchInCatalog(searchInCatalogInput.value)" type="button" class="search_btn">
                    <span class="icon-header-search-icon"></span>
                  </button>
                </form>
              </div>
              <div class="ml-sm-4 ml-2">
                <a routerLink="/order" class="header_cart">
                  <div class="header_cart__icon mr-sm-2">
                    <span class="icon-header-cart-icon"
                      ><span class="path1"></span><span class="path2"></span
                    ></span>
                    <div *ngIf="cartCount > 0" class="header_cart__count">{{cartCount}}</div>
                  </div>
                  <div class="header_cart__txt text-nowrap">
                    <strong class="bigger">{{cartCost  | currency:'':'' | replace:',00':''}}</strong> руб.
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<app-location-popup></app-location-popup>
<app-login-form *ngIf="!user"></app-login-form>
