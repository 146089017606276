import { GetCurrentCitySuccess } from './../actions/common.actions';
import { CommonActions, ECommonActions } from '../actions/common.actions';
import { initialCommonState, ICommonState } from '../state/common.state';
import { act } from '@ngrx/effects';

export const commonReducers = (
  state = initialCommonState,
  action: CommonActions
): ICommonState => {
  switch (action.type) {
    case ECommonActions.GetUserSuccess: {
      return {
        ...state,
        user: action.payload
      };
    }
    case ECommonActions.GetTenantSuccess: {
      return {
        ...state,
        tenant: action.payload
      };
    }
    case ECommonActions.GetTenantContextSuccess: {
      return {
        ...state,
        tenant: action.payload
      };
    }
    case ECommonActions.GetCategoriesSuccess: {
      return {

        ...state,
        categories: action.payload.categories,
        categoriesList: action.payload.categoriesList
      };
    }
    case ECommonActions.GetCurrentCategorySuccess: {
      return {
        ...state,
        currentCategory: action.payload
      };
    }

    case ECommonActions.GetCitiesSuccess: {
      return {
        ...state,
        cities: action.payload.cities
      };
    }

    case ECommonActions.GetCurrentCitySuccess: {
      return {
        ...state,
        currentCity: action.payload
      };
    }

    case ECommonActions.GetCartInfoSuccess: {
      return {
        ...state,
        cartInfo: action.payload
      };
    }

    default:
      return state;
  }
};

