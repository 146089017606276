import { Router } from '@angular/router';
import { AuthService } from './../shared/services/auth.service';
import { LoginFormComponent } from './../login-form/login-form.component';
import { TenantFullContextVM, City } from './../services/main.service';
import { LocationPopupComponent } from './../location-popup/location-popup.component';
import {
  selectCategories,
  selectTenantInfo,
  selectCities,
  selectCurrentCity,
  selectCartInfo,
} from './../../core/store/selectors/common.selector';
import { GetUser, GetTenant } from './../../core/store/actions/common.actions';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/core/store/state/app.state';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import {  TenantInfoVM, UserInfoDto } from '../services/main.service';
import { selectUserInfo } from 'src/core/store/selectors/common.selector';

@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  user: UserInfoDto;
  tenantInfo: TenantInfoVM;
  currentCityName: string;
  cartCount:number;
  cartCost:number;
  tenantContext: TenantFullContextVM;
  cities: City[];
  @ViewChild(LocationPopupComponent) locationPopup: LocationPopupComponent;
  @ViewChild(LoginFormComponent) loginFormPopup: LoginFormComponent;

  constructor(
    private _store: Store<IAppState>,
    private authService: AuthService,
    private router: Router
  ) {
    this.cities = [];
  }
  ngOnDestroy(): void {}

  openLocationPopup() {
    this.locationPopup.locationPopupVisible = true;
  }

  openLoginPopup() {
    this.loginFormPopup.loginPopupVisible = true;
  }

  searchInCatalog(value){
    if(value && value != "") this.router.navigate(['search'], {queryParams: {q: value}});
  }

  searchInCatalogInputonKeyDown(e, value){
    if(e.keyCode === 13) this.searchInCatalog(value)
  }

  async logout() {
    this.authService.lastAuthenticatedPath = this.router.url;
    if (this.router.url.includes('personal')) {
      this.authService.lastAuthenticatedPath = '';
    }

    this.authService.logOut();

    this.user = this.authService.getUserInfo();


  }

  ngOnInit(): void {
    let self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectUserInfo)).subscribe((result) => {
        self.user = result;
        console.log(self.user);
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(selectTenantInfo)).subscribe((result) => {
        self.tenantInfo = result;
        self.tenantContext = self.tenantInfo?.fullContextVm;
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(selectCurrentCity)).subscribe((result) => {
        if (result) self.currentCityName = result?.title;
      })
    );
    this.subscriptions.add(
      this._store.pipe(select(selectCities)).subscribe((result) => {
        if (result) self.cities = result;
      })
    );
    selectCities

    this.subscriptions.add(
      this._store.pipe(select(selectCartInfo)).subscribe((result) => {
        if (result){
          self.cartCount = result?.count;
          self.cartCost= result?.cost;
        }
      })
    );
  }
}
