import { Router } from '@angular/router';
import { FilterEventModel } from './../catalog-top-filter/filter-event-model';
import {
  CategoryItemVM,
  CatalogFiltersVM,
  Manufacturer,
} from './../services/main.service';
import { selectCurrentCategory } from './../../core/store/selectors/common.selector';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/core/store/state/app.state';

@Component({
  selector: 'app-catalog-layout',
  templateUrl: './catalog-layout.component.html',
  styleUrls: ['./catalog-layout.component.less'],
})
@AutoUnsubscribe()
export class CatalogLayoutComponent implements OnInit, OnDestroy, OnChanges {
  categories: CategoryItemVM[];
  currentCategory: CategoryItemVM;
  takeCount: number = 24;
  sortType: string;
  incomedFilterModel: CatalogFiltersVM;
  outputFilterModel: CatalogFiltersVM;
  subscriptions: Subscription = new Subscription();

  constructor(private _store: Store<IAppState>, private router: Router) {}

  ngOnDestroy(): void {}

  changeTakeCount(value) {
    this.takeCount = value;
  }

  changeSortType(value) {
    this.sortType = value;
  }

  ngOnInit(): void {
    const self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectCurrentCategory)).subscribe((result) => {
        if (result) {
          self.currentCategory = result;
        } else {
        }
      })
    );
  }

  proccessFilterModel(filterModel: CatalogFiltersVM) {
    this.incomedFilterModel = filterModel;
  }

  ngOnChanges(): void {}
}
