<footer class="py-4 border-top">
  <div class="container">
    <div class="row mb-lg-3 mb-4">
      <div class="col-xl-3 col-12">
        <div class="d-block d-lg-flex d-xl-block justify-content-between align-items-center text-center text-lg-left">
        <div class="mb-4 mb-xl-4 mb-lg-0">
          <a href="#" class="logo">
            <img src="{{tenantInfo?.logo}}" alt="{{tenantInfo.title}}" width="210">
          </a>
        </div>
        <div>
          <div class="d-block d-lg-none d-xl-block">
            <h3>Связь с нами</h3>
          </div>
          <div class="my-4">
            <a href="tel:{{ tenantInfo?.phoneNumber }}" class="contact_phone"><span class="icon-header-phone-icon mr-2"></span><span class="bigger">{{tenantInfo?.phoneNumber}}</span></a>
            <p class="contact_time mb-0">{{tenantInfo?.workSchedule}}</p>
          </div>
        </div>
        <ul class="list_inline my-4 justify-content-lg-start justify-content-center">
          <li *ngIf="tenantContext?.vkLink">
            <a target="_blank" [href]="tenantContext?.vkLink" class="social_network" style="background: #4e7db2"><i class="fa fa-vk" aria-hidden="true"></i></a>
          </li>
          <li *ngIf="tenantContext?.okLink">
            <a target="_blank" [href]="tenantContext?.okLink" class="social_network" style="background: #f29216"><i class="fa fa-odnoklassniki" aria-hidden="true"></i></a>
          </li>
          <li *ngIf="tenantContext?.telegramLink">
            <a target="_blank" [href]="tenantContext?.telegramLink" class="social_network" style="background: #2ba0d9"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px"><path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M14.948,15.599 C14.876,15.953 14.469,16.114 14.182,15.901 L10.008,12.808 C9.755,12.620 9.410,12.630 9.167,12.832 L6.853,14.757 C6.585,14.982 6.180,14.857 6.077,14.519 L4.470,9.245 L0.319,7.663 C-0.102,7.502 -0.106,6.894 0.314,6.729 L17.350,0.020 C17.712,-0.122 18.084,0.201 18.006,0.588 L14.948,15.599 ZM13.931,3.302 L5.818,8.403 C5.507,8.598 5.361,8.982 5.461,9.341 L6.338,12.487 C6.401,12.710 6.718,12.687 6.748,12.457 L6.976,10.731 C7.019,10.407 7.171,10.107 7.406,9.884 L14.101,3.531 C14.227,3.412 14.077,3.211 13.931,3.302 Z"/></svg></a>
          </li>
          <li *ngIf="tenantContext?.facebookLink">
            <a target="_blank" [href]="tenantContext?.facebookLink" class="social_network" style="background: #3d5a98"><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
          <li *ngIf="tenantContext?.instagramLink">
            <a target="_blank" [href]="tenantContext?.instagramLink" class="social_network" style="background: #c63f5e"><i class="fa fa-instagram" aria-hidden="true"></i></a>
          </li>
          <li *ngIf="tenantContext?.youTubeLink">
            <a target="_blank" [href]="tenantContext?.youTubeLink" class="social_network" style="background: #f62d2a"><i class="fa fa-youtube" aria-hidden="true"></i></a>
          </li>
          <li *ngIf="tenantContext?.twitterLink">
            <a target="_blank" [href]="tenantContext?.twitterLink" class="social_network" style="background: #29a2e8"><i class="fa fa-twitter" aria-hidden="true"></i></a>
          </li>
        </ul>
        <!-- <a href="javascript:void(0)" class="btn btn_custom1 btn_xmd w-100 modal-trigger" style="max-width: 220px" data-target="sendModal"><span class="icon mr-2"><img src="../../assets/images/footer-write-icon.svg" alt=""></span>Написать нам</a> -->
      </div>
      </div>
      <div class="col-xl-9 col-12">
        <!-- <div class="d-lg-flex mb-4 mt-5 mt-lg-0 text-center">
          <h3>Подпишитесь на рассылку</h3>
          <div class="ml-lg-5 flex-fill">
            <form class="subscribe_form">
              <input type="text" class="form-control" placeholder="Введите адрес электронной почты">
              <input type="submit" value="Подписаться" class="btn btn_grey btn_xmd">
            </form>
          </div>
        </div> -->
        <div class="d-lg-flex justify-content-between">
          <div class="pr-4 d-lg-block">
            <h3>Страницы сайта</h3>
            <ul class="list_TwoCol txt_lgrey text-nowrap">
              <li *ngIf="tenantContext?.aboutPageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/o_kompanii">О компании</a>
              </li>
              <li *ngIf="tenantContext?.requisitesPageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/rekvizity">Реквизиты</a>
              </li>
              <li *ngIf="tenantContext?.rewiewsPageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/reviews">Отзывы</a>
              </li>
              <li *ngIf="tenantContext?.deliveryPageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/dostavka">Доставка и оплата</a>
              </li>
              <li *ngIf="tenantContext?.guaranteePageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/garantyy">Гарантии</a>
              </li>
              <li *ngIf="tenantContext?.returnPageInnerHtml">
                <a (click)="scrollToTop()" routerLink="/vozvrat">Обмен и возврат</a>
              </li>
            </ul>
          </div>
          <div *ngIf="categories && categories.length > 0" class="pr-4 d-none d-lg-block">
            <h3>Категории</h3>
            <ul class="list_TwoCol txt_lgrey text-nowrap">
              <li *ngFor="let item of categories">
                <a (click)="scrollToTop()" routerLink="{{'/catalog/' + item?.alias}}">{{item.title}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="row">
        <div class="col-lg-5 col-12 text-center text-lg-left">
          <p class="mt-2">© {{getCurrentYear()}}, интернет-магазин {{tenantInfo?.title}}</p>
        </div>
        <div class="col-lg-7 col-12">
          <ul class="list_inline justify-content-lg-end justify-content-center">
            <li *ngIf="tenantContext?.privacyPolicyPageInnerHtml" class="mx-2">
              <a routerLink="/privacy-policy" class="link mr-sm-3">Политика конфиденциальности</a>
            </li>
            <li *ngIf="tenantContext?.termsOfUsePageInnerHtml" class="mx-2 mr-sm-0">
              <a routerLink="/terms-of-use" class="link">Условия передачи информации</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
