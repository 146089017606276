import { Component, OnInit } from '@angular/core';
import { MainNewsApiClient } from '../services/main.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.less']
})
export class NewsListComponent implements OnInit {
  items: any;
  constructor(private newsApiClient: MainNewsApiClient) {}

  ngOnInit(): void {
    this.loadNews();
  }

  loadNews() {
    let self = this;
    this.newsApiClient.getList().toPromise().then((res) => {
      self.items = res;
    });
  }

}
