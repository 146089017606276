<div *ngIf="currentFilterModel && currentFilterModel.filtersNeeded" class="modal filterModal" id="filterModal">
  <div class="modal_overlay"></div>
  <div class="modal-dialog" role="document">
    <div class="side_filter card_v1 mb-3">
      <div class="p-3 side_filter__head">
        <h3>Подбор параметров</h3>
        <button (click)="modalService.closeCatalogMobileFilters()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="px-lg-3 pb-3">
        <form>
          <!-- <div class="checkbox_wrap d-block d-lg-none p-3">
            <input type="checkbox" id="in_stock" checked class="mr-3">
            <div class="checkmark"></div>
            <label class="m-0" for="in_stock"><strong>Товары в наличии</strong></label>
          </div> -->
          <div class="side_filter__list">
            <div class="side_filter__item collapse_wrap__item">
              <div class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('price')}" (click)="handleFilterPanelClick('price')"><strong>Цена</strong></div> 
              <div class="collapse_content" [hidden]="!checkIfOpened('price')">
                <span class="resetFilterText" (click)="resetFilterByCode('price')">Сбросить</span>
                <div class="d-flex my-3 justify-content-between">
                  <div class="rangeInput_wrap mr-2">
                    <span class="rangeInput_label">От</span>
                    <input type="number" [(ngModel)]="currentFilterModel.minPrice" name="minPrice" class="form-control sliderValue dark" id="sliderValueTill1">
                  </div>
                  <div class="rangeInput_wrap ml-2">
                    <span class="rangeInput_label">До</span>
                    <input type="number" [(ngModel)]="currentFilterModel.maxPrice" name="maxPrice"  class="form-control sliderValue dark" id="sliderValueSince1">
                  </div>
                </div>
                <!-- <div class="my-3">
                  <div class="d-flex justify-content-between my-3">
                    <div class="range__point">
                      9 300
                    </div>
                    <div class="range__point">
                      15 300
                    </div>
                    <div class="range__point">
                      43 900
                    </div>
                  </div>
                </div> -->
              </div>
            </div>

            <div *ngIf="incomedFilterModel.manufacturers && incomedFilterModel.manufacturers.length > 0" class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('manufacturer')}" (click)="handleFilterPanelClick('manufacturer')"><strong>Производитель</strong></a>
              <div class="collapse_content" [hidden]="!checkIfOpened('manufacturer')">
                <span class="resetFilterText" (click)="resetFilterByCode('manufacturer')">Сбросить</span>
                <div class="params_list">
                  <ul class="list_custom">
                    <li *ngFor="let manufacturer of incomedFilterModel.manufacturers">
                      <div class="checkbox_wrap">
                        <label class="m-0">
                          <input type="checkbox" [checked]="isChecked('manufacturer', manufacturer)" class="mr-3" (change)="manufacturerCheckBoxChanged($event, manufacturer)">
                          <div class="checkmark"></div>
                        {{manufacturer.title}}</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div *ngIf="incomedFilterModel.materials && incomedFilterModel.materials.length > 0"  class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('material')}" (click)="handleFilterPanelClick('material')"><strong>Материал</strong></a>
              <div class="collapse_content" [hidden]="!checkIfOpened('material')">
                <span class="resetFilterText" (click)="resetFilterByCode('material')">Сбросить</span>
                <div class="params_list">
                  <ul class="list_custom">
                    <li *ngFor="let material of incomedFilterModel.materials">
                      <div class="checkbox_wrap">
                        <label class="m-0">
                          <input type="checkbox" [checked]="isChecked('material', material)" class="mr-3" (change)="materialCheckBoxChanged($event, material)">
                          <div class="checkmark"></div>
                        {{material}}</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('height')}" (click)="handleFilterPanelClick('height')"><strong>Высота</strong></a>
              <div class="collapse_content" [hidden]="!checkIfOpened('height')">
                <span class="resetFilterText" (click)="resetFilterByCode('height')">Сбросить</span>
                <div class="d-flex my-3 justify-content-between">
                  <div class="rangeInput_wrap mr-2">
                    <span class="rangeInput_label">От</span>
                    <input type="number" [(ngModel)]="currentFilterModel.minHeight" name="minHeight" class="form-control sliderValue dark" id="sliderValueTill5">
                  </div>
                  <div class="rangeInput_wrap ml-2">
                    <span class="rangeInput_label">До</span>
                    <input type="number" [(ngModel)]="currentFilterModel.maxHeight" name="maxHeight" class="form-control sliderValue dark" id="sliderValueSince5">
                  </div>
                </div>

              </div>
            </div>

            <div class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('width')}" (click)="handleFilterPanelClick('width')"><strong>Ширина</strong></a>
              <div class="collapse_content" [hidden]="!checkIfOpened('width')">
                <span class="resetFilterText" (click)="resetFilterByCode('width')">Сбросить</span>
                <div class="d-flex my-3 justify-content-between">
                  <div class="rangeInput_wrap mr-2">
                    <span class="rangeInput_label">От</span>
                    <input type="number" [(ngModel)]="currentFilterModel.minWidth" name="minWidth" class="form-control sliderValue dark" id="sliderValueTill4">
                  </div>
                  <div class="rangeInput_wrap ml-2">
                    <span class="rangeInput_label">До</span>
                    <input type="number" [(ngModel)]="currentFilterModel.maxWidth" name="maxWidth" class="form-control sliderValue dark" id="sliderValueSince6">
                  </div>
                </div>
              </div>
            </div>
            <div class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('depth')}" (click)="handleFilterPanelClick('depth')"><strong>Глубина</strong></a>
              <div class="collapse_content" [hidden]="!checkIfOpened('depth')">
                <span class="resetFilterText" (click)="resetFilterByCode('depth')">Сбросить</span>
                <div class="d-flex my-3 justify-content-between">
                  <div class="rangeInput_wrap mr-2">
                    <span class="rangeInput_label">От</span>
                    <input type="number" [(ngModel)]="currentFilterModel.minDepth" name="minDepth" class="form-control sliderValue dark" id="sliderValueTill2">
                  </div>
                  <div class="rangeInput_wrap ml-2">
                    <span class="rangeInput_label">До</span>
                    <input type="number" [(ngModel)]="currentFilterModel.maxDepth" name="maxDepth" class="form-control sliderValue dark" id="sliderValueSince2">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="side_filter__item collapse_wrap__item">
              <a href="javascript:void(0);" class="side_filter__link collapse_link" [ngClass]="{'opened': checkIfOpened('color')}" (click)="handleFilterPanelClick('color')"><strong>Цвет</strong></a>
              <div class="collapse_content mt-3" [hidden]="!checkIfOpened('color')" content="user-scalable=no">
                <div class="d-flex flex-wrap">
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #00bcd4"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #607d8b"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #795548"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ff5722"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ff9800"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ffeb3b"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #795548"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ff5722"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ff9800"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #ffeb3b"></label>
                  </div>
                  <div class="checkbox_color">
                    <input type="checkbox">
                    <label style="background: #2196f3"></label>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="text-center my-3 px-3 px-lg-0">
            <button type="submit" (click)="applyFilter()" class="btn btn_xmd btn_red w-100 my-2">Показать</button>
            <a href="javascript:void(0);" (click)="resetFilter()" class="text-nowrap my-2 d-block"><span class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px"><path fill-rule="evenodd"  fill="rgb(0, 0, 0)" d="M9.881,0.662 L5.558,4.985 L9.881,9.308 C10.039,9.466 10.039,9.723 9.881,9.881 C9.802,9.960 9.698,10.000 9.594,10.000 C9.491,10.000 9.387,9.960 9.308,9.881 L4.985,5.558 L0.663,9.881 C0.584,9.960 0.480,10.000 0.376,10.000 C0.273,10.000 0.169,9.960 0.090,9.881 C-0.068,9.723 -0.068,9.466 0.090,9.308 L4.413,4.985 L0.090,0.662 C-0.068,0.504 -0.068,0.248 0.090,0.090 C0.248,-0.069 0.505,-0.069 0.663,0.090 L4.985,4.412 L9.308,0.090 C9.466,-0.069 9.723,-0.069 9.881,0.090 C10.039,0.248 10.039,0.504 9.881,0.662 Z"/></svg></span> <span>Сбросить</span></a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
