import { ModalService } from './../services/modal.service';
import { GetCurrentCategory } from 'src/core/store/actions/common.actions';
import { FilterEventModel } from './filter-event-model';
import {
  CatalogFiltersVM,
  MainCatalogApiClient,
} from './../services/main.service';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-catalog-top-filter',
  templateUrl: './catalog-top-filter.component.html',
  styleUrls: ['./catalog-top-filter.component.less'],
})
export class CatalogTopFilterComponent implements OnInit {
  @Output() changeTakeCountEvent = new EventEmitter<number>();
  @Output() changeSortTypeEvent = new EventEmitter<string>();
  takeCount: number = 24;
  sortType: string;
  filters: CatalogFiltersVM;
  currentManufacturer: string;
  @Input() isSearchPage:boolean = false;
  constructor(
    private catalogApiClient: MainCatalogApiClient,
    @Inject(DOCUMENT) private doc,
    public modalService:ModalService
  ) {}

  changeTakeCount(value: any) {
    this.takeCount = value;
    this.changeTakeCountEvent.emit(value);
  }

  changeSortType(value: string) {
    this.sortType = value;
    this.changeSortTypeEvent.emit(value);
  }


  ngOnInit(): void {
    const self = this;
    this.catalogApiClient
      .getFilterModel()
      .toPromise()
      .then((filterModel) => {
        self.filters = filterModel;
      });
  }
}
