<section>
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-12">
          <app-left-layout></app-left-layout>
        </div>


        <div class="col-xl-9 col-lg-8 col-12">
          <app-breadcrumbs></app-breadcrumbs>
            <h1>Новости</h1>
            <div>

              <div *ngFor="let item of items" class="article_card my-3 py-2">
                <div class="article_card__pic">
                  <a *ngIf="!item.alias.includes('http')" routerLink="{{'/news/' + item.alias}}"><img [src]="item.image" alt="{{item.header}}"></a>
                  <a *ngIf="item.alias.includes('http')" href="{{item.alias}}" target="_blank"><img [src]="item.image" alt="{{item.header}}"></a>
                </div>
                <div class="article_card__info">
                  <h3 *ngIf="!item.alias.includes('http')"><a routerLink="{{'/news/' + item.alias}}">{{item.header}}</a></h3>
                  <h3 *ngIf="item.alias.includes('http')"><a  href="{{item.alias}}" target="_blank">{{item.header}}</a></h3>
                  <p class="article_card__date">{{item.published}}</p>
                  <div class="article_card__text mb-3">
                    <p> {{(item.content | slice:0:180) + '...'}}</p>
                  </div>
                  <div class="bottom">
                    <a *ngIf="!item.alias.includes('http')" routerLink="{{'/news/' + item.alias}}" class="link">Читать далее<span class="ml-2"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
                    <a *ngIf="item.alias.includes('http')" href="{{item.alias}}" target="_blank" class="link">Перейти<span class="ml-2"><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
                  </div>
                </div>
              </div>

            </div>



          </div>
      </div>
    </div>
  </section>



