<section *ngIf="user">
  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-12">
        <app-left-layout></app-left-layout>
      </div>

      <div class="col-xl-9 col-lg-8 col-12">
        <app-breadcrumbs></app-breadcrumbs>

        <h1>Изменение учетной записи</h1>
        <div class="my-4">
          <p *ngIf="validationMessage" style="color:red; font-size:14px; text-align:center">{{validationMessage}}</p>
          <form>
            <div class="row">
              <div class="col-xl-4 col-12">
                <div class="form-group">
                  <label>ФИО<sup class="text_red bigger ml-1">*</sup></label>
                  <input type="text" [(ngModel)]="fullName" name="fullName" class="form-control"
                    placeholder="Фамилия и имя" required>
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Телефон<sup class="text_red bigger ml-1">*</sup></label>
                  <input type="text" [(ngModel)]="phoneNumber" name="phoneNumber" class="form-control tel" mask="0 000 000-0000"
                    placeholder="+7 (999) 999-99-99" required>
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Электронная почта<sup class="text_red bigger ml-1">*</sup></label>
                  <input type="text" [(ngModel)]="email" name="email" class="form-control" placeholder="email@mail.ru"
                    required>
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Новый пароль</label>
                  <input type="password" [(ngModel)]="password" name="password" class="form-control" placeholder="">
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Подтверждение пароля</label>
                  <input type="password" [(ngModel)]="confirmPassword" name="confirmPassword" class="form-control"
                    placeholder="">
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Адрес доставки</label>
                  <input type="text" [(ngModel)]="deliveryAddress" name="deliveryAddress" class="form-control"
                    placeholder="Начните вводить адрес">
                </div>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn_custom btn_md btn_red" type="button" (click)="save()"
                style="width: 180px">Сохранить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
