import { SeoService } from './services/seo.service';
import {
  GetCartInfo,
  GetCities,
  GetCurrentCategory,
  GetTenantContext,
} from './../core/store/actions/common.actions';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/core/store/state/app.state';
import {
  selectTenantInfo,
  selectCategories,
  selectTenantContextInfo,
} from 'src/core/store/selectors/common.selector';
import {
  GetUser,
  GetTenant,
  GetCategories,
} from 'src/core/store/actions/common.actions';
import { ActivationEnd, Router } from '@angular/router';
import { MainSeoApiClient } from './services/main.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
declare let $: any;

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  dynamicScripts: any;
  favIcon: HTMLLinkElement;
  isTenantExists: boolean;
  platformBrowser: boolean;

  constructor(
    private _store: Store<IAppState>,
    private router: Router,
    private seoService: SeoService,
    private seoApiClient: MainSeoApiClient,
    @Inject(DOCUMENT) private doc,
    @Inject(PLATFORM_ID) private platformId: Object,
    private elementRef: ElementRef
  ) {
    this.favIcon = this.doc.getElementById('appIcon');
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.startup();
  }
  ngOnDestroy(): void {}

  async startup() {
    const self = this;
    this._store.dispatch(new GetTenant());
    this._store.dispatch(new GetUser());
    this._store.dispatch(new GetTenantContext());
    this._store.dispatch(new GetCategories());
    this._store.dispatch(new GetCities());
    this._store.dispatch(new GetCartInfo());

    this.subscriptions.add(
      this._store.pipe(select(selectCategories)).subscribe((result) => {
        if (result?.length > 0) {
          let url = self.router.url;
          if (url.includes('/catalog/')) {
            let alias = url.replace('/catalog/', '');
            if (alias != '')
              self._store.dispatch(new GetCurrentCategory(alias));
          }
        }
      })
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof ActivationEnd) {
          let routerState = (event.snapshot as any)._routerState;
          let params = event.snapshot.params;
          this.seoApiClient
            .getForPage(routerState.url, params['category'], params['good'])
            .toPromise()
            .then((seoResult) => {
              if (seoResult) this.seoService.setupSeo(seoResult);
            });
        }
      })
    );

    this.subscriptions.add(
      this._store.pipe(select(selectTenantInfo)).subscribe((tenant) => {
        // const title = `Интернет-магазин ${tenant?.title || ''}`;
        // self.seoService.setTitle(title);
        if (tenant !== undefined) {
          this.isTenantExists = tenant !== null ? true : false;
          this.dynamicScripts = tenant?.dynamicScripts;
          if (tenant.fullContextVm) {
            if (tenant?.dynamicHeadScripts) {
              if (this.platformBrowser) {
                $('head').append(tenant?.dynamicHeadScripts);
                // $('body').prepend(tenant?.dynamicScripts);
              }
              if(isPlatformServer(this.platformId)){
                let headContainer = this.doc.head;
                let bodyContainer = this.doc.body;
                headContainer.insertAdjacentHTML(
                  'beforeend',
                  tenant?.dynamicHeadScripts
                );
                // bodyContainer.insertAdjacentHTML(
                //   'afterbegin',
                //   tenant?.dynamicScripts
                // );
              }
            }
          }

          this.favIcon.href = tenant?.faviconIcon;
        }
      })
    );
  }
}
