<form *ngIf="!submitted" #kitchenForm="ngForm">
  <div class="my-3">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="my-2"><strong>Имя</strong><sup class="text_red bigger ml-1">*</sup></label>
          <input [(ngModel)]="model.fullName" #fullName="ngModel" name="fullName" type="text" class="form-control dark" placeholder="Фамилия и имя" required>
          <div [hidden]="fullName.valid || (fullName.pristine && !fullName.touched)"
          class="alert alert-danger">
       Заполните поле
     </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="my-2"><strong>Телефон</strong><sup class="text_red bigger ml-1">*</sup></label>
          <input minlength="10" [(ngModel)]="model.phoneNumber" #phoneNumber="ngModel" name="phoneNumber" type="text" class="form-control dark tel" mask="0 000 000-0000" placeholder="+7 (999) 999-99-99" required>
          <div [hidden]="phoneNumber.valid || (phoneNumber.pristine && !phoneNumber.touched)"
             class="alert alert-danger">
          Заполните поле
        </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="my-2"><strong>E-mail</strong><sup class="text_red bigger ml-1">*</sup></label>
          <input [(ngModel)]="model.email" #email="ngModel" name="email" type="text" class="form-control dark" placeholder="email@mail.ru" required>
          <div [hidden]="email.valid || (email.pristine && !email.touched)"
          class="alert alert-danger">
       Заполните поле
     </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="my-2"><strong>Модель кухни или ссылка</strong><sup class="text_red bigger ml-1">*</sup></label>
          <input [(ngModel)]="model.kitchenModel" #kitchenModel="ngModel" name="kitchenModel" type="text" class="form-control dark" placeholder="" required>
          <div [hidden]="kitchenModel.valid || (kitchenModel.pristine && !kitchenModel.touched)"
          class="alert alert-danger">
       Заполните поле
     </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="form-group mt-3">
          <img src="images/captcha.jpeg" alt="">
        </div>
      </div> -->
      <!-- <div class="col-sm-6">
        <div class="form-group">
          <label class="my-2"><strong>Символы с картинки</strong><sup class="text_red bigger ml-1">*</sup></label>
          <input type="text" class="form-control dark" placeholder="Кухня'Шарлотта' (продаётся помодульно)" required>
        </div>
      </div> -->
    </div>
  </div>
  <div class="my-3">
    <p class="title">Тип вашей кухни:</p>
    <div class="row">
      <div class="col-sm-3 col-6">
        <div class="radio_type">
          <img src="../../assets/images/type1.png" alt="Прямая кухня" width="120" height="120" class="radio_type__pic">
          <input [(ngModel)]="model.kitchenType" value="Прямая кухня" type="radio" class="radio" id="type1" name="kitchen_type" >
          <label for="type1">Прямая кухня</label>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="radio_type">
          <img src="../../assets/images/type2.png" alt="Г-образная кухня (левая)" width="120" height="120" class="radio_type__pic">
          <input [(ngModel)]="model.kitchenType" value="Г-образная кухня (левая)" type="radio" class="radio" id="type2" name="kitchen_type">
          <label for="type2">Г-образная кухня (левая)</label>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="radio_type">
          <img src="../../assets/images/type3.png" alt="Г-образная кухня (правая)" width="120" height="120" class="radio_type__pic">
          <input [(ngModel)]="model.kitchenType" value="Г-образная кухня (правая)" type="radio" class="radio" id="type3" name="kitchen_type">
          <label for="type3">Г-образная кухня (правая)</label>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="radio_type">
          <img src="../../assets/images/type4.png" alt="П-образная кухня" width="120" height="120" class="radio_type__pic">
          <input [(ngModel)]="model.kitchenType" value="П-образная кухня" type="radio" class="radio" id="type4" name="kitchen_type">
          <label for="type4">П-образная кухня</label>
        </div>
      </div>
    </div>
  </div>
  <div class="my-3">
    <p class="title">Размеры:</p>
    <div class="row">
      <div class="col-md-3 col-4">
        <div class="form-group">
          <label class="my-2">A</label>
          <input [(ngModel)]="model.sizeA" name="sizeA" type="text" class="form-control dark" placeholder="3200">
        </div>
      </div>
      <div class="col-md-3 col-4">
        <div class="form-group">
          <label class="my-2">B</label>
          <input  [(ngModel)]="model.sizeB" name="sizeB" type="text" class="form-control dark" placeholder="2200">
        </div>
      </div>
      <div class="col-md-3 col-4">
        <div class="form-group">
          <label class="my-2">C</label>
          <input  [(ngModel)]="model.sizeC" name="sizeC" type="text" class="form-control dark" placeholder="3200">
        </div>
      </div>

    </div>
  </div>
  <div class="form-group">
    <label class="my-2"><strong>Если в Ваши размеры входит бытовая техника, укажите какая именно и её размеры:</strong></label>
    <textarea  [(ngModel)]="model.applianceInfo" name="applianceInfo" rows="5" class="form-control dark"></textarea>
  </div>
  <div class="my-3">
    <div class="form-group">
      <label class="my-2"><strong>Дополнительная информация:</strong></label>
      <textarea  [(ngModel)]="model.additionalInfo" name="additionalInfo" rows="5" class="form-control dark"></textarea>
    </div>
  </div>
  <div class="form-group text-lg-right text-center mt-sm-5 mt-4">
    <button class="btn btn_custom btn_md btn_red" type="button" (click)="submitForm(kitchenForm)" >Отправить заявку</button>
  </div>
</form>
<p class="submitText" *ngIf="submitted">Спасибо за вашу заявку. Вы получите ответ в скором времени!</p>
