import { CategoryItemVM, TenantFullContextVM } from './../services/main.service';
import {
  selectCategories,
  selectCategoriesList,
  selectTenantContextInfo,
  selectTenantInfo,
} from './../../core/store/selectors/common.selector';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/core/store/state/app.state';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TenantInfoVM } from '../services/main.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  tenantInfo: TenantInfoVM;
  tenantContext: TenantFullContextVM;
  categories: CategoryItemVM[] = [];

  constructor(private _store: Store<IAppState>, private el: ElementRef) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    let self = this;
    this.subscriptions.add(
      this._store.pipe(select(selectTenantContextInfo)).subscribe((result) => {
        self.tenantInfo = result;
        self.tenantContext = self.tenantInfo?.fullContextVm;
      })
    );
    this.subscriptions.add(
      this._store.pipe(select(selectCategoriesList)).subscribe((result) => {
        if (result && result.length > 0) {
          let filtered = result.filter(x => x.isSelectionType == false);
          result = filtered.sort((n1, n2) => {
            if (n1.priority > n2.priority) {
              return 1;
            }

            if (n1.priority < n2.priority) {
              return -1;
            }

            return 0;
          });
          self.categories = result.slice(0,9);
        }
      })
    );
  }

  scrollToTop(){
    this.el.nativeElement.closest('body').scrollIntoView({behavior: 'smooth'});
  }

  getCurrentYear(){
    return new Date().getFullYear();
  }
}
