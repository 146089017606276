import { SeoService } from './../services/seo.service';
import {
  MainGoodApiClient,
  GoodPreviewCardVM,
  GoodCardVM,
} from './../services/main.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ResolveEnd, Router } from '@angular/router';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { CartService } from '../services/cart.service';
import { Store } from '@ngrx/store';
import { GetCartInfo } from 'src/core/store/actions/common.actions';
import { IAppState } from 'src/core/store/state/app.state';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@Component({
  selector: 'app-good-page',
  templateUrl: './good-page.component.html',
  styleUrls: ['./good-page.component.less'],
})
@AutoUnsubscribe()
export class GoodPageComponent implements OnInit, OnDestroy {
  category: string;
  goodAlias: string;
  isKitchen = false;
  good: GoodCardVM;
  modules: GoodPreviewCardVM[];
  images: GalleryItem[] = [];
  exhibitionImages: GalleryItem[] = [];
  isServer: boolean;
  galleryOptions: any;
  subscriptions: Subscription = new Subscription();
  structures: GoodPreviewCardVM[];

  constructor(
    private _store: Store<IAppState>,
    private router: Router,
    private route: ActivatedRoute,
    private goodApiClient: MainGoodApiClient,
    private cartService: CartService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if(isPlatformBrowser(this.platformId)) window.scrollTo(0, 0);
    else this.isServer = true;
  }
  ngOnDestroy(): void {}

  addToCart(id: string, price: number) {
    this.cartService.addToCartById(id, price);
    this._store.dispatch(new GetCartInfo());
  }

  orderModules(type) {
    switch (type) {
      case 'priceDesc': {
        this.modules = this.modules.sort((n1, n2) => {
          if (n1.price < n2.price) {
            return 1;
          }

          if (n1.price > n2.price) {
            return -1;
          }

          return 0;
        });
        break;
      }
      case 'priceAsc': {
        this.modules = this.modules.sort((n1, n2) => {
          if (n1.price > n2.price) {
            return 1;
          }

          if (n1.price < n2.price) {
            return -1;
          }

          return 0;
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  ngOnInit(): void {
    let self = this;
    this.category = this.route.snapshot.paramMap.get('category');
    this.goodAlias = this.route.snapshot.paramMap.get('good');

    this.goodApiClient
      .getByAlias(this.goodAlias)
      .toPromise()
      .then((result) => {
        if (result == null) {
          self.router.navigate(['not-found']);
        } else {
          self.good = result;
          if (self.good.categoryAlias === 'kukhni') self.isKitchen = true;
          if (isPlatformBrowser(this.platformId)) {
            self.good.images.forEach((image) => {
              self.images.push(new ImageItem({ src: image, thumb: image }));
            });
            if(self.good.exhibitionImages){
              self.good.exhibitionImages.forEach((image) => {
                self.exhibitionImages.push(new ImageItem({ src: image, thumb: image }));
              });
            }
           
          }

          this.goodApiClient
            .getModules(self.good.id)
            .toPromise()
            .then((modules) => {
              self.modules = modules;
            });

          this.goodApiClient
            .getStructures(self.good.id)
            .toPromise()
            .then((structures) => {
              self.structures = structures;
            });
        }
      });
  }
}
